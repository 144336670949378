import { createSelectorFunctions } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { ConversionController } from "@/pages/Buy/utils";
import { QuoteResponse } from "@/requests/quote";
import {
  NetworkValue,
  Networks,
  PaymentType,
  PaymentTypes,
  StableCoinValue,
  StableCoins,
  supportedCurrencies,
  BUY_MAX_LIMIT_AMT,
  SELL_MAX_LIMIT_AMT,
  isFiat,
  getNetwork,
  getStableCoinType,
} from "@/utils/currencies";

export const TransactionType = {
  BUY: "BUY",
  SELL: "SELL",
} as const;

type ValueOf<T> = T[keyof T];

export type TransactionValue = ValueOf<typeof TransactionType>;

export type BuySellState = {
  transactionType: TransactionValue;
  fiatAmount: number;
  stableCoinAmount: number;
  referralAdjustedFiatAmount: number;
  referralAdjustedCoinAmount: number;
  requestType: PaymentType;
  network: NetworkValue;
  stableCoin: StableCoinValue;
  loading: boolean;
  quoteEngine: ConversionController | null;
  dialogPage1: boolean;
};

// TransactionType logic:
// First check if query params from sessionStorage set leftSidelabel/rightSidelabel
// If not set, then check sessionStorage for last transactionType
// otherwise default to BUY

const urlParams = new URLSearchParams(window.location.search);
const LSLParams = urlParams.get("leftSideLabel");
const RSLParams = urlParams.get("rightSideLabel");

const sessionTransactionType = sessionStorage.getItem("transactionType");
const leftSideLabel = LSLParams || sessionStorage.getItem("leftSideLabel") || "";
const rightSideLabel = RSLParams || sessionStorage.getItem("rightSideLabel") || "";

const isLeftFiat = leftSideLabel && isFiat(leftSideLabel);
const isRightFiat = rightSideLabel && isFiat(rightSideLabel);

const stableCoinVal = isLeftFiat
  ? getStableCoinType(rightSideLabel.substring(0, rightSideLabel.indexOf("-")))
  : isRightFiat
    ? getStableCoinType(leftSideLabel.substring(0, leftSideLabel.indexOf("-")))
    : StableCoins.USDC;

const networkVal = isLeftFiat
  ? getNetwork(
      rightSideLabel.substring(
        rightSideLabel.indexOf("-") + 1,
        rightSideLabel.length
      )
    )
  : isRightFiat
    ? getNetwork(
        leftSideLabel.substring(
          leftSideLabel.indexOf("-") + 1,
          leftSideLabel.length
        )
      )
    : Networks.ethereum;
//console.log("NETWORKVAL:", networkVal);
//console.log("STABLECOINVAL:", stableCoinVal);

// If we have query params,  enforce initial tType from that first
// Otherwise, enforce from sessionTransactionType
let initialTransactionType:TransactionValue = TransactionType.BUY;
if (LSLParams || RSLParams) {
  if (isRightFiat) {
    initialTransactionType = TransactionType.SELL;
  } 
} else {
  //initialTransactionType = sessionTransactionType == TransactionType.SELL ? TransactionType.SELL : TransactionType.BUY;
  if (sessionTransactionType == TransactionType.SELL.toString()) {
    initialTransactionType = TransactionType.SELL;    
  }
}

const initialState: BuySellState = {
  //transactionType: (sessionStorage.getItem('transactionType') === TransactionType.SELL ) ? TransactionType.SELL : TransactionType.BUY,
  transactionType: initialTransactionType,
  loading: false,
  fiatAmount: 1000,
  stableCoinAmount: 0,
  referralAdjustedFiatAmount: 0,
  referralAdjustedCoinAmount: 0,
  //network: Networks.ethereum,
  network: networkVal,
  requestType: PaymentTypes.REQUEST,
  //stableCoin: StableCoins.USDC,
  stableCoin: stableCoinVal,
  quoteEngine: null,
  dialogPage1: true,
};

type BuySellActions = {
  reset: () => void;
  setTransactionType: (transactionType: TransactionValue) => void;
  setNetwork: (network: NetworkValue) => void;
  setFiatAmount: (value: number) => void;
  setRequestType: (option: PaymentType) => void;
  setStableCoinAmount: (value: number) => void;
  setStableCoin: (currency: StableCoinValue) => void;
  setLoading: (currency: boolean) => void;
  setQuotes: (quoteData: QuoteResponse) => void;
  setReferralAdjustedFiatAmount: (value: number) => void;
  setReferralAdjustedCoinAmount: (value: number) => void;
  setDialogPage1: (value: boolean) => void;
};

export const createBuySellStore = create<BuySellState & BuySellActions>()(
  immer<BuySellState & BuySellActions>((set) => ({
    ...initialState,
    reset: () => {
      set(initialState);
      sessionStorage.clear();
    },
    setTransactionType: (transactionType: TransactionValue) =>
      set((state) => {
        sessionStorage.setItem("transactionType", transactionType);
        state.transactionType = transactionType;
        if (state.quoteEngine) {
          if (transactionType === TransactionType.SELL) {
            //state.requestType = PaymentTypes.ETRANSFER;
            // Need to recalculate amounts for switch to SELL
            // LOGIC:  it seems LHS value always stays the same and RHS value is recalculated
            // ORG:   LHS is FIAT and RHS is stablecoin  AFT:  RHS is Stablecoin LHS Fiat
            state.stableCoinAmount = state.fiatAmount;

            // Recalculate Fiat amount row
            state.fiatAmount = state.quoteEngine?.convertCoinAmountToFiat(
              state.stableCoinAmount,
              state.stableCoin,
              state.network,
              state.transactionType,
              "left"
            );
            if (state.fiatAmount > SELL_MAX_LIMIT_AMT) {
              state.fiatAmount = SELL_MAX_LIMIT_AMT;

              state.stableCoinAmount =
                state.quoteEngine?.convertFiatToCoinAmount(
                  state.fiatAmount,
                  state.stableCoin,
                  state.network,
                  state.transactionType,
                  "left"
                );
            }
          } else {
            // Need to recalculate amounts for swithc to BUY
            state.fiatAmount = state.stableCoinAmount;

            state.stableCoinAmount = state.quoteEngine?.convertFiatToCoinAmount(
              state.fiatAmount,
              state.stableCoin,
              state.network,
              state.transactionType,
              "left"
            );
          }
        }
      }),
    setNetwork: (network: NetworkValue) =>
      set((state) => {
        state.network = network;
        if (!supportedCurrencies[network].includes(state.stableCoin)) {
          state.stableCoin = supportedCurrencies[network][0];
        }
        if (state.quoteEngine) {
          if (state.transactionType === "BUY") {
            state.stableCoinAmount = state.quoteEngine?.convertFiatToCoinAmount(
              state.fiatAmount,
              state.stableCoin,
              network,
              state.transactionType,
              "left"
            );
          } else {
            state.fiatAmount = state.quoteEngine?.convertCoinAmountToFiat(
              state.stableCoinAmount,
              state.stableCoin,
              network,
              state.transactionType,
              "left"
            );
          }
        }
      }),
    setRequestType: (option: PaymentType) =>
      set((state) => {
        state.requestType = option;
      }),
    setFiatAmount: (value: number) =>
      set((state) => {
        state.fiatAmount = value;
        if (state.quoteEngine) {
          state.stableCoinAmount =
            state.quoteEngine.convertFiatToCoinAmount(
              state.fiatAmount,
              state.stableCoin,
              state.network,
              state.transactionType,
              // if BUY, stablecoin side is RSV
              state.transactionType === "BUY" ? "left" : "right"
            ) ?? 0;
        }
      }),
    setStableCoinAmount: (value: number) =>
      set((state) => {
        state.stableCoinAmount = value;
        if (state.quoteEngine) {
          state.fiatAmount =
            state.quoteEngine.convertCoinAmountToFiat(
              state.stableCoinAmount,
              state.stableCoin,
              state.network,
              state.transactionType,
              // if BUY, fiat side is RSV
              state.transactionType === "BUY" ? "right" : "left"
            ) ?? 0;

          if (
            state.transactionType === "BUY" &&
            state.fiatAmount >= BUY_MAX_LIMIT_AMT
          ) {
            state.fiatAmount = BUY_MAX_LIMIT_AMT;
            // set stablecoin recalculated to limit
            state.stableCoinAmount =
              state.quoteEngine.convertFiatToCoinAmount(
                state.fiatAmount,
                state.stableCoin,
                state.network,
                state.transactionType,
                // if BUY, stablecoin side is RSV
                "left"
              ) ?? 0;
          } else if (
            state.transactionType === "SELL" &&
            state.fiatAmount >= SELL_MAX_LIMIT_AMT
          ) {
            state.fiatAmount = SELL_MAX_LIMIT_AMT;
            // set stablecoin recalculated to limit
            state.stableCoinAmount =
              state.quoteEngine.convertFiatToCoinAmount(
                state.fiatAmount,
                state.stableCoin,
                state.network,
                state.transactionType,
                // if BUY, stablecoin side is RSV
                "right"
              ) ?? 0;
          }
        }
      }),
    setReferralAdjustedFiatAmount: (value: number) =>
      set((state) => {
        state.referralAdjustedFiatAmount = value;
      }),
    setReferralAdjustedCoinAmount: (value: number) =>
      set((state) => {
        state.referralAdjustedCoinAmount = value;
      }),
    setDialogPage1: (value: boolean) =>
      set((state) => {
        state.dialogPage1 = value;
      }),
    setStableCoin: (stableCoin: StableCoinValue) =>
      set((state) => {
        state.stableCoin = stableCoin;
        if (state.quoteEngine) {
          console.log("SETSTABLECOIN");
          state.stableCoinAmount = state.quoteEngine?.convertFiatToCoinAmount(
            state.fiatAmount,
            stableCoin,
            state.network,
            state.transactionType,
            state.transactionType === "BUY" ? "left" : "right"
          );
        }
      }),
    setLoading: (loading: boolean) =>
      set((state) => {
        state.loading = loading;
      }),
    setQuotes: (quoteData: QuoteResponse) =>
      set((state) => {
        if (!state.quoteEngine) {
          state.quoteEngine = new ConversionController(quoteData);
        } else {
          state.quoteEngine.updateQuote(quoteData);
        }
        if (state.transactionType === TransactionType.BUY) {
          state.stableCoinAmount = state.quoteEngine.convertFiatToCoinAmount(
            state.fiatAmount,
            state.stableCoin,
            state.network,
            state.transactionType,
            "left"
          );
        } else {
          state.fiatAmount = state.quoteEngine.convertCoinAmountToFiat(
            state.stableCoinAmount,
            state.stableCoin,
            state.network,
            state.transactionType,
            "left"
          );
        }
      }),
  }))
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useBuySellStore = createSelectorFunctions(createBuySellStore);
