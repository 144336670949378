import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import * as Select from "@radix-ui/react-select";

import { useSignupStore } from "@/reducers";

const options = [
  "Virtual currency exchange to purchase goods or services",
  "Virtual currency exchange for family support",
  "Contributing to personal savings",
  "General living expenses",
];

export const IntendedPurposeSelector = () => {
  const { purpose } = useSignupStore();
  const setPurpose = useSignupStore.use.setPurpose();
  return (
    <Select.Root
      required
      value={purpose}
      onValueChange={(value) => {
        setPurpose(value);
      }}
    >
      <Select.Trigger className="text-pebble dark:text-snow py-2 h-full w-full inline-flex items-end leading-none gap-[5px] min-w-[150px] justify-between outline-none border-b-rock border-b-2">
        <span style={{ textAlign: "left" }}>
          <div
            className={purpose ? "text-black dark:text-white" : "text-gray-400"}
          >
            <Select.Value placeholder="Select option" />
          </div>
        </span>
        <Select.Icon className="text-gray-400">
          <ChevronDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden max-w-[90vw] md:max-w-80">
          <Select.ScrollUpButton className="p-2.5">
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport>
            {options.map((option) => {
              return (
                <Select.Item
                  key={option}
                  value={option}
                  style={{ textAlign: "left" }}
                  className="hover:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                >
                  <Select.ItemText>{option}</Select.ItemText>
                </Select.Item>
              );
            })}
          </Select.Viewport>
          <Select.ScrollDownButton className="p-2.5">
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};
