import clsx from "clsx";
import { IMaskInput } from "react-imask";

type CurrencyInputProps = {
  label: string;
  id: string;
  amount: number;
  setAmount: (val: number) => void;
  decimal?: number;
  disabled?: boolean;
  min: number;
  max: number;
  autoFocus?: boolean;
};

export const CurrencyInput = ({
  label,
  amount,
  setAmount,
  disabled,
  decimal = 2,
  id,
  min,
  max,
  autoFocus,
  ...props
}: CurrencyInputProps) => {
  const inputClass = clsx(
    "w-full text-gravel dark:text-white text-xl pb-1 pt-2 bg-transparent border-b-2 border-rock invalid:border-red focus:outline-none focus:text-clementine dark:focus:text-sun invalid:border-color-red-500 rounded-none",
    { "border-b-red-500": amount <= 0 }
  );
  return (
    <div className="flex flex-col gap-3">
      <label htmlFor={id} className="uppercase">
        {label}
      </label>
      <IMaskInput
        className={inputClass}
        disabled={disabled}
        normalizeZeros
        id={id}
        mask={Number}
        placeholder="00.00"
        value={amount.toString()}
        min={min}
        max={max}
        thousandsSeparator=","
        radix="."
        scale={decimal}
        autoFocus={autoFocus}
        onAccept={(value, mask, e) => {
          if (e) {
            if (value === "") {
              setAmount(0);
            } else {
              setAmount(Number.parseFloat(mask.unmaskedValue));
            }
          }
        }}
        {...props}
      />
    </div>
  );
};
