import { FormEvent, useState } from "react";
import { Group, Label } from "@radix-ui/react-select";
import { Button } from "@/components/Button/Button";
import { TextInput } from "@/components/widget-components";
import { ImageData } from "@/requests/signup";

import { Select, SelectItem } from "./DocumentTypeSelect";
import { FileUploader } from "./FileUploader";

type DocOptionGroup = {
  groupName: string;
  options: string[];
  values: string[];
};

const supportingDocOptions: DocOptionGroup[] = [
  {
    groupName: "Name & Financial",
    options: ["Credit card statement", "Bank statement", "Mortgage statement"],
    values: ["creditCardStatement", "bankStatement", "mortgageStatement"],
  },
  {
    groupName: "Name & Address",
    options: [
      "Utility bill",
      "Phone bill",
      "Property tax statement",
      "Notice of assessment",
      "T4",
    ],
    values: ["utility", "phonebill", "propertyTax", "noa", "t4Statement"],
  },
  {
    groupName: "Name & DOB",
    options: ["Insurance document"],
    values: ["insuranceDocument"],
  },
];

type SupportingDocumentsProps = {
  onConfirm: (
    fileData: ImageData,
    accountType: string,
    accountNumber: string,
    companyName: string
  ) => void;
};

export const SupportingDocuments = ({
  onConfirm,
}: SupportingDocumentsProps) => {
  const [accountType, setAccountType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [error, setError] = useState("");
  const [fileData, setFileData] = useState<ImageData | null>(null);

  const handleSuccess = (fileData: ImageData) => {
    setFileData(fileData);
    setError("");
  };

  const handleConfirm = (e: FormEvent) => {
    e.preventDefault();
    if (fileData) {
      onConfirm(fileData, accountType, accountNumber, companyName);
    }
  };

  return (
    <form className="flex flex-col gap-5" onSubmit={handleConfirm}>
      <div>Please upload a photo with the following:</div>
      <div>
        Please upload full cover page of document. Truncated or redacted
        documents will not be accepted.
      </div>
      <div className="px-5 md:px-10">
        <Select onValueChange={(val) => setAccountType(val)}>
          {supportingDocOptions.map((group) => (
            <Group key={group.groupName}>
              <Label className=" text-xs leading-[25px] text-rock">
                {group.groupName}
              </Label>
              {group.options.map((option, index) => (
                <SelectItem key={option} value={group.values[index]}>
                  {option}
                </SelectItem>
              ))}
            </Group>
          ))}
        </Select>
      </div>
      <div>
        <TextInput
          autoFocus
          placeholder="Company/Organization Name"
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </div>
      <div>
        <TextInput
          autoFocus
          placeholder="Account Number"
          onChange={(e) => setAccountNumber(e.target.value)}
        />
      </div>
      {error && <div className="text-rose-600">{error}</div>}
      {fileData && (
        <div className="flex justify-center invisible md:visible">
          <img src={fileData.url} width={100} />
        </div>
      )}
      {!fileData && (
        <div className="hidden md:flex justify-center">
          <div className="h-[150px] w-[100px] border-dashed border border-gray-300 dark:border-neutral-200 flex justify-center items-center px-2 py-2">
            Image
          </div>
        </div>
      )}
      <FileUploader
        onUploadComplete={handleSuccess}
        onError={setError}
        customButtonText=""
        showUploadIcon={true}
      />
      <Button
        type="submit"
        disabled={
          !fileData || !!error || !accountType || !accountNumber || !companyName
        }
      >
        Confirm
      </Button>
    </form>
  );
};
