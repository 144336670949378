import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { CopyIcon } from "@radix-ui/react-icons";

import InteracLogo from "@/assets/interac-circle.png";
import {
  getOrderStatusByTransactionId,
  OrderStatusResponse,
} from "@/requests/order";

import { DarkModeMenu } from "@/panels/signup/DarkmodeSignUpPanel";
import { usePageStateStore, TransactionType } from "@/reducers";
import { WidgetLayout } from "../Buy/WidgetLayout";

const Confirmation = () => {
  const transactionId =
    usePageStateStore.use.tx_id() || localStorage.getItem("transactionId");
  // const [transactionType, setTransactionType] = useState<typeof buySellStore.transactionType>(
  //   buySellStore.transactionType || (sessionStorage.getItem('transactionType') as typeof buySellStore.transactionType)
  // );
  //const [transactionType] = useBuySellStore.use.transactionType();
  let transactionType: any = TransactionType.BUY;
  const sessionType = sessionStorage.getItem("transactionType");
  if (sessionType === "BUY") {
    transactionType = TransactionType.BUY;
  } else if (sessionType === "SELL") {
    transactionType = TransactionType.SELL;
  }

  const [orderDetails, setOrderDetails] = useState<OrderStatusResponse | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const parent = searchParams.get("parent"); // Check the `parent` query parameter.

  useEffect(() => {
    if (transactionId) {
      localStorage.setItem("transactionId", transactionId);
      fetchOrderDetails(transactionId);
    } else {
      console.error("Transaction ID is missing");
    }
  }, [transactionId]);

  // useEffect(() => {
  //   localStorage.setItem('transactionType', transactionType);
  // }, [transactionType]);

  // useEffect(() => {
  //   if (buySellStore.transactionType) {
  //     setTransactionType(buySellStore.transactionType);
  //   }
  // }, [buySellStore.transactionType]);

  useEffect(() => {
    console.log("TRANSACTIONTYPE:", transactionType);
  }, []);

  const fetchOrderDetails = async (transactionId: string) => {
    try {
      const response = await getOrderStatusByTransactionId(transactionId);
      console.log("API Response:", response);
      setOrderDetails(response.data);
    } catch (error) {
      console.error("Failed to fetch order details:", error);
      setError("Failed to fetch order details");
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!orderDetails) {
    return <div>Loading...</div>;
  }

  const copyToClipboard = (text: string) => {
    try {
      navigator.clipboard.writeText(text).then(
        () => {},
        () => {
          fallbackCopyToClipboard(text);
        }
      );
    } catch {
      fallbackCopyToClipboard(text);
    }
  };

  const fallbackCopyToClipboard = (text: string) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed";
    textarea.style.opacity = "0";
    textarea.style.pointerEvents = "none";
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
    } catch {
      alert("Failed to copy. Please copy manually.");
    }
    document.body.removeChild(textarea);
  };

  const formatAddress = (address: string | null | undefined) => {
    if (!address) {
      return null;
    }
    const lines = [];
    for (let i = 0; i < address.length; i += 25) {
      lines.push(address.slice(i, i + 25));
    }
    return (
      <div className="text-right">
        {lines.map((line, index) => (
          <span key={index} className="block">
            {line}
          </span>
        ))}
      </div>
    );
  };

  const getConfirmationUrl = () => {
    if (!orderDetails) return "https://etherscan.io/";

    const { leftSideLabel, rightSideLabel } = orderDetails;

    if (
      ["USDT-BSC", "BUSD-BSC", "USDC-BSC", "USDT-B", "BUSD-B"].includes(
        leftSideLabel
      ) ||
      ["USDT-BSC", "BUSD-BSC", "USDC-BSC", "USDT-B", "BUSD-B"].includes(
        rightSideLabel
      )
    ) {
      return "https://bscscan.com/";
    } else if (
      ["USDC-POLY", "CADC-POLY", "USDC-P", "CADC-P"].includes(leftSideLabel) ||
      ["USDC-POLY", "CADC-POLY", "USDC-P", "CADC-P"].includes(rightSideLabel)
    ) {
      return "https://polygonscan.com/";
    } else if (
      ["USDC-ARB", "CADC-ARB"].includes(leftSideLabel) ||
      ["USDC-ARB", "CADC-ARB"].includes(rightSideLabel)
    ) {
      return "https://arbiscan.io/";
    } else if (
      ["USDC-ETH", "CADC-ETH"].includes(leftSideLabel) ||
      ["USDC-ETH", "CADC-ETH"].includes(rightSideLabel)
    ) {
      return "https://etherscan.io/";
    } else if (leftSideLabel === "USDC-OP" || rightSideLabel === "USDC-OP") {
      return "https://optimistic.etherscan.io/";
    } else if (
      leftSideLabel === "USDC-AVAX" ||
      rightSideLabel === "USDC-AVAX"
    ) {
      return "https://snowtrace.io/";
    } else if (leftSideLabel === "USDC-FTM" || rightSideLabel === "USDC-FTM") {
      return "https://ftmscan.com/";
    } else if (leftSideLabel === "USDC-GNO" || rightSideLabel === "USDC-GNO") {
      return "https://gnosisscan.io/";
    } else if (
      leftSideLabel === "USDC-CELO" ||
      rightSideLabel === "USDC-CELO"
    ) {
      return "https://celoscan.io/";
    } else if (leftSideLabel === "USDC-SX" || rightSideLabel === "USDC-SX") {
      return "https://explorerl2.sx.technology/";
    } else if (
      leftSideLabel === "USDC-BASE" ||
      rightSideLabel === "USDC-BASE"
    ) {
      return "https://basescan.org/";
    } else if (leftSideLabel === "USDC-SOL" || rightSideLabel === "USDC-SOL") {
      return "https://solscan.io/";
    } else {
      return "https://etherscan.io/";
    }
  };

  const confirmationUrl = getConfirmationUrl();
  const isInIframe = window.self !== window.top;
  let isParentDashboardPage = false;

  if (isInIframe) {
    try {
      isParentDashboardPage =
        window.parent.location.pathname.includes("/dashboard");
    } catch (error) {
      console.error(
        "Unable to access parent location. Ensure the iframe and parent page are on the same origin.",
        error
      );
    }
  }

  return (
    <WidgetLayout showInteracLabel={false} showPoweredByLabel={true}>
      {window.self === window.top && parent !== "landing" && (
        <div className="absolute top-4 sm:top-10 right-0">
          <DarkModeMenu showSignOutButton={true} />
        </div>
      )}
      <div className="flex items-center justify-center min-h-screen overflow-hidden">
        <div
          className="w-full max-w-4xl px-4 sm:px-10 dark:text-white"
          style={{
            position: "absolute",
            top: window.self === window.top ? "10%" : "9%",
            width: "100%",
          }}
        >
          {transactionType === TransactionType.SELL && (
            <div className="space-y-6 mb-20 sm:mb-12">
              <div className="mb-6">
                <h2 className="text-lg font-bold mb-4 sm:mb-6 text-left text-black dark:text-white mt-8 sm:mt-12">
                  ORDER SUMMARY
                </h2>
                <div className="space-y-3 sm:space-y-4">
                  <div className="flex items-center justify-between text-black dark:text-white">
                    <span className="font-semibold text-left">You Pay</span>
                    <span>
                      {parseFloat(orderDetails.leftSideValue).toFixed(2)}{" "}
                      {orderDetails.leftSideLabel}
                    </span>
                  </div>
                  <div className="flex items-center justify-between text-black dark:text-white">
                    <span className="font-semibold text-left">You Receive</span>
                    <span>
                      {parseFloat(orderDetails.rightSideValue).toFixed(2)}{" "}
                      {orderDetails.rightSideLabel}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mb-12">
                <h3 className="text-lg font-bold mb-6 text-left text-black dark:text-white">
                  INSTRUCTIONS
                </h3>
                <div className="space-y-4">
                  <div className="flex items-start justify-between text-black dark:text-white">
                    <span className="font-semibold text-left">Send:</span>
                    <div className="flex flex-row items-center">
                      <span className="mr-2">
                        {parseFloat(orderDetails.leftSideValue).toFixed(2)}{" "}
                        {orderDetails.leftSideLabel}
                      </span>
                      <button
                        onClick={() =>
                          copyToClipboard(orderDetails.leftSideValue)
                        }
                        className="hover:text-blue-700 dark:hover:text-blue-300 text-blue-500 flex items-center"
                      >
                        <CopyIcon />
                      </button>
                    </div>
                  </div>
                  <div className="flex items-start justify-between text-black dark:text-white">
                    <span className="font-semibold text-left">
                      From your wallet:
                    </span>
                    <div className="flex-grow flex items-center justify-between">
                      <a
                        href={`${confirmationUrl}address/${orderDetails.wallet}#tokentxns`}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        className="text-blue-500 hover:underline break-words whitespace-pre-wrap"
                        style={{
                          width: "calc(100% - 24px)",
                          textAlign: "right",
                        }}
                      >
                        {formatAddress(orderDetails.wallet)}
                      </a>
                      <div style={{ width: "24px" }}></div>
                    </div>
                  </div>
                  <div className="flex items-start justify-between text-black dark:text-white">
                    <span className="font-semibold text-left">
                      To Paytrie's wallet:
                    </span>
                    <div className="flex flex-row items-center">
                      {orderDetails.leftSideLabel === "USDC-SOL" ||
                      orderDetails.rightSideLabel === "USDC-SOL" ? (
                        <>
                          <a
                            href={`${confirmationUrl}address/${import.meta.env.VITE_PAYTRIE_SOLANA_WALLET_ADDR}#tokentxns`}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            className="text-blue-500 hover:underline break-words whitespace-pre-wrap"
                          >
                            {formatAddress(
                              import.meta.env.VITE_PAYTRIE_SOLANA_WALLET_ADDR
                            )}
                          </a>
                          <button
                            onClick={() =>
                              copyToClipboard(
                                import.meta.env.VITE_PAYTRIE_SOLANA_WALLET_ADDR
                              )
                            }
                            className="hover:text-blue-700 dark:hover:text-blue-300 ml-2 text-blue-500 flex items-center"
                          >
                            <CopyIcon />
                          </button>
                        </>
                      ) : (
                        <>
                          <a
                            href={`${confirmationUrl}address/${import.meta.env.VITE_PAYTRIE_WALLET_ADDR}#tokentxns`}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            className="text-blue-500 hover:underline break-words whitespace-pre-wrap"
                          >
                            {formatAddress(
                              import.meta.env.VITE_PAYTRIE_WALLET_ADDR
                            )}
                          </a>
                          <button
                            onClick={() =>
                              copyToClipboard(
                                import.meta.env.VITE_PAYTRIE_WALLET_ADDR
                              )
                            }
                            className="hover:text-blue-700 dark:hover:text-blue-300 ml-2 text-blue-500 flex items-center"
                          >
                            <CopyIcon />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Link
                to={`/status?id=${transactionId}`}
                className="w-full py-8 px-4 mt-4 bg-black text-white dark:bg-white dark:text-black border-black text-center block text-center"
              >
                Order Status
              </Link>
            </div>
          )}

          {transactionType === TransactionType.BUY && (
            <div className="space-y-6 mb-20 sm:mb-12">
              <div>
                <h2 className="text-lg font-bold mb-4 sm:mb-6 text-left text-black dark:text-white mt-8 sm:mt-12">
                  PAYMENT METHOD
                </h2>
                <p className="text-black dark:text-white">
                  Accept Interac Request Money
                </p>
              </div>

              <div>
                <h2 className="text-lg font-bold mb-4 sm:mb-6 text-left text-black dark:text-white">
                  ORDER SUMMARY
                </h2>
                <div className="space-y-4">
                  <div className="flex items-center justify-between text-black dark:text-white">
                    <span className="font-semibold text-left">You pay</span>
                    <span>
                      {parseFloat(orderDetails.leftSideValue).toFixed(2)}{" "}
                      {orderDetails.leftSideLabel}
                    </span>
                  </div>
                  <div className="flex items-center justify-between text-black dark:text-white">
                    <span className="font-semibold text-left">You receive</span>
                    <span>
                      {parseFloat(orderDetails.rightSideValue).toFixed(2)}{" "}
                      {orderDetails.rightSideLabel}
                    </span>
                  </div>
                  <div className="flex items-start justify-between text-black dark:text-white">
                    <span className="font-semibold text-left">Wallet</span>
                    <div className="flex-grow text-right break-words whitespace-pre-wrap">
                      <a
                        href={`${confirmationUrl}address/${orderDetails.wallet}#tokentxns`}
                        rel="nofollow noopener noreferrer"
                        target="_blank"
                        className="text-blue-500 break-all"
                      >
                        {formatAddress(orderDetails.wallet)}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <a
                  href={`https://etransfer.interac.ca/reqPayment/eml/${orderDetails.atb_status}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full bg-black text-white border-black py-4 sm:py-6 px-4 dark:text-black dark:bg-white text-center flex items-center justify-center hover:bg-gray-900 dark:hover:bg-gray-100 transition-colors"
                >
                  <span className="mr-2">PAY WITH</span>
                  <img
                    src={InteracLogo}
                    alt="Interac Logo"
                    className="w-6 h-6"
                  />
                </a>
                <Link
                  to={`/status?id=${transactionId}`}
                  className="w-full py-4 sm:py-6 bg-white text-black dark:bg-black dark:text-white border-black text-center block hover:bg-gray-100 dark:hover:bg-gray-900 transition-colors"
                >
                  ORDER STATUS
                </Link>
              </div>
            </div>
          )}

          {/* {transactionType === 'RMT' && (
          <>
            <div className="mb-12">
              <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-left text-black dark:text-white">
                Payment method
              </h2>
              <p className="text-black dark:text-white">Send manual e-Transfer</p>
            </div>
            <div className="mb-12">
              <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-left text-black dark:text-white">
                Order summary
              </h2>
              <div className="space-y-4">
                <div className="flex items-center justify-between text-black dark:text-white">
                  <span className="font-semibold text-left">You pay:</span>
                  <span>{parseFloat(orderDetails.leftSideValue).toFixed(2)} CAD</span>
                </div>
                <div className="flex items-center justify-between text-black dark:text-white">
                  <span className="font-semibold text-left">You receive:</span>
                  <span>{parseFloat(orderDetails.rightSideValue).toFixed(2)} {orderDetails.rightSideLabel}</span>
                </div>
                <div className="flex items-center justify-between text-black dark:text-white">
                  <span className="font-semibold text-left">Wallet:</span>
                  <span className="break-words">{orderDetails.wallet}</span>
                </div>
              </div>
            </div>
            <div className="mb-12">
              <h3 className="text-2xl sm:text-3xl font-bold mb-6 text-left text-black dark:text-white">
                Instructions
              </h3>
              <div className="space-y-4">
                <p className="text-black dark:text-white">
                  Send an e-Transfer to complete your order.
                </p>
                <div className="flex items-start justify-between text-black dark:text-white">
                  <span className="font-semibold text-left">Amount:</span>
                  <span>{parseFloat(orderDetails.leftSideValue).toFixed(2)} CAD</span>
                </div>
                <div className="flex items-start justify-between text-black dark:text-white">
                  <span className="font-semibold text-left">To:</span>
                  <span>{orderDetails.email}</span>
                </div>
                <p className="text-black dark:text-white">
                  Within the next {orderDetails.timeLimit} minutes
                </p>
              </div>
            </div>
            <button
              className="w-full py-4 mt-4 bg-black text-white dark:bg-white dark:text-black border-black border-2 text-center"
            >
              Order Status
            </button>
          </>
        )} */}
        </div>
      </div>
    </WidgetLayout>
  );
};

export default Confirmation;
