import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/contexts/auth-context";
import { Button } from "@/components/Button/Button";
import { LoadingIcon } from "@/components/LoadingIcon";
import { DropdownMenuSelect } from "@/components/widget-components";
import {
  TransactionType,
  useBuySellStore,
} from "@/reducers/transactionReducer";
import { getQuote } from "@/requests/quote";
import { BuySellSwitcher } from "./BuySellSwitcher";
import { FiatCoinRow, StableCoinRow } from "./CoinChooser";
import { InfoPage } from "./InfoPage";
import { SubTotal } from "./Subtotal";
import { WidgetLayout } from "./WidgetLayout";
import {
  NetworkValue,
  NetworkOptions,
  BUY_MAX_LIMIT_AMT,
  SELL_MAX_LIMIT_AMT,
  MIN_LIMIT_AMT,
  isFiat,
} from "@/utils/currencies";

export const BuySell = () => {
  const [showInfoPage, setShowInfoPage] = useState(false);

  const { isAuth } = useAuth();
  const navigate = useNavigate();

  const network = useBuySellStore.use.network();
  const transactionType = useBuySellStore.use.transactionType();
  const setTransactionType = useBuySellStore.use.setTransactionType();
  const [stableCoinAmount, fiatAmount, stableCoin] = [
    useBuySellStore.use.stableCoinAmount(),
    useBuySellStore.use.fiatAmount(),
    useBuySellStore.use.stableCoin(),
  ];
  const [setStableCoinAmount, setFiatAmount] = [
    useBuySellStore.use.setStableCoinAmount(),
    useBuySellStore.use.setFiatAmount(),
  ];
  const [loading, setLoading] = [
    useBuySellStore.use.loading(),
    useBuySellStore.use.setLoading(),
  ];
  const [reset] = [useBuySellStore.use.reset()];
  const setQuotes = useBuySellStore.use.setQuotes();

  const isValidTrade = stableCoinAmount > 0 && fiatAmount > 0;
  const maintenanceMode = (import.meta.env.VITE_PAYTRIE_MAINTENANCE_MODE === 'TRUE') ? true : false;

  const quote = () => {
    getQuote()
      .then((response) => {
        setQuotes(response.data);
      })
      .catch(() => {
        console.warn("Unable to load quotes");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const LSLParams = urlParams.get("leftSideLabel");
    const RSLParams = urlParams.get("rightSideLabel");
    // Reset transaction State
    reset();
    //setTransactionType(TransactionType.BUY);
    if (!LSLParams && !RSLParams) {
      console.log("RESET TRANSACTIONTYPE:");
      setTransactionType(TransactionType.BUY);
    }

    if (!loading) {
      quote();
    }

    const h = setInterval(() => {
      quote();
    }, 60000);

    return () => {
      clearInterval(h);
    };
  }, []);

  // Fetch query params and set sessionStorage fields accordingly
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const LSVParams = urlParams.get("leftSideValue");
    const LSLParams = urlParams.get("leftSideLabel");
    const RSLParams = urlParams.get("rightSideLabel");
    const emailParam = urlParams.get("email");
    const referralCodeParam = urlParams.get("ref");
    const phoneParam = urlParams.get("phone");
    const fNameParam = urlParams.get("firstName");
    const lNameParam = urlParams.get("lastName");
    const dob1Param = urlParams.get("dob1");
    const dob2Param = urlParams.get("dob2");
    const dob3Param = urlParams.get("dob3");
    const addr1Param = urlParams.get("address1");
    const addr2Param = urlParams.get("address2");
    const cityParam = urlParams.get("city");
    const provParam = urlParams.get("province");
    const postalParam = urlParams.get("postal");

    sessionStorage.setItem("leftSideValue", LSVParams || "");
    sessionStorage.setItem("leftSideLabel", LSLParams || "");
    sessionStorage.setItem("rightSideLabel", RSLParams || "");
    localStorage.setItem("email", emailParam || "");
    localStorage.setItem("ref", referralCodeParam || "");
    localStorage.setItem("phone", phoneParam || "");
    localStorage.setItem("firstName", fNameParam || "");
    localStorage.setItem("lastName", lNameParam || "");
    localStorage.setItem("dob1", dob1Param || "");
    localStorage.setItem("dob2", dob2Param || "");
    localStorage.setItem("dob3", dob3Param || "");
    localStorage.setItem("address1", addr1Param || "");
    localStorage.setItem("address2", addr2Param || "");
    localStorage.setItem("city", cityParam || "");
    localStorage.setItem("province", provParam || "");
    localStorage.setItem("postal", postalParam || "");

    if (isFiat(LSLParams) && LSVParams) {
      setFiatAmount(Number(LSVParams));
    }
    if (!isFiat(LSLParams) && LSVParams) {
      setStableCoinAmount(Number(LSVParams));
    }


  }, []);

  const setMax = (transactionType: string) => {
    // If BUY TRANSCATION
    if (transactionType === TransactionType.BUY) {
      setFiatAmount(BUY_MAX_LIMIT_AMT);
    } else {
      setFiatAmount(SELL_MAX_LIMIT_AMT);
    }
  };

  const setMin = () => {
    setFiatAmount(MIN_LIMIT_AMT);
  };

  if (loading) {
    return (
      <WidgetLayout>
        <div className="my-auto flex justify-center">
          <LoadingIcon />
        </div>
      </WidgetLayout>
    );
  }

  return (
    <WidgetLayout>
      <div className="flex flex-row justify-between items-center py-25">
        <div className="flex gap-5">
          <BuySellSwitcher />
          <DropdownMenuSelect />
        </div>
      </div>
      <div className="relative flex flex-col gap-5">
        {/* <button
          className="absolute right-0 top-3 text-xs inline-flex"
          onClick={() => setShowInfoPage(true)}
          aria-label="learn more"
        >
          How to {transactionType.toLowerCase()} stablecoins&nbsp;
          <InfoCircledIcon />
        </button> */}
        <div className="mt-10">
          {transactionType === TransactionType.BUY && <FiatCoinRow />}
          {transactionType === TransactionType.SELL && <StableCoinRow />}
        </div>
      </div>
      <div className="flex items-center">
        <button
          style={{ fontSize: "15px", textDecoration: "underline" }}
          onClick={() => setMin()}
        >
          MIN
        </button>
        &nbsp;&nbsp;/&nbsp;&nbsp;
        <button
          style={{ fontSize: "15px", textDecoration: "underline" }}
          onClick={() => setMax(transactionType)}
        >
          MAX
        </button>
      </div>
      <div
        className="flex-grow p-1 flex flex-col justify-center"
        style={{ maxHeight: "100px" }}
      >
        <SubTotal />
      </div>

      {transactionType === TransactionType.BUY && <StableCoinRow />}
      {transactionType === TransactionType.SELL && <FiatCoinRow />}

      {maintenanceMode && (
        <Button
          disabled={true}
        >
          Under Maintenance
        </Button>  
      )}


      {!maintenanceMode && (
        <Button
          disabled={!isValidTrade}
          onClick={() =>
            isAuth !== null ? navigate("/Transactions") : navigate("/EasyLogin")
          }
          className="inline-flex items-center py-8 px-4 w-full lg:justify-center lg:gap-2"
          style={{ justifyContent: "center", textAlign: "center" }}
        >
          <span>
            {transactionType === TransactionType.BUY ? "BUY" : "SELL"}{" "}
            {(Math.floor(stableCoinAmount * 100) / 100).toFixed(2)} {stableCoin}{" "}
            ON {NetworkOptions[network as NetworkValue].name}
          </span>
          <span style={{ paddingBottom: "4px", marginLeft: "8px" }}>
            &#x27f6;
          </span>
        </Button>
      )}
      {showInfoPage && (
        <InfoPage
          showBack={true}
          onHide={() => {
            setShowInfoPage(false);
          }}
        />
      )}
    </WidgetLayout>
  );
};
