import React, { useState, useEffect } from "react";
import { MoonIcon, SunIcon } from "@radix-ui/react-icons";

import ExpandIcon from "@/assets/layout-sidebar-left-expand.svg";
import CollapseIcon from "@/assets/layout-sidebar-left-collapse.svg";
import PaytrieFullIcon from "@/assets/paytrie-full-rgb.svg";
import { usePageStateStore } from "@/reducers";
import Sidebar from "./Sidebar";

const Topbar: React.FC = () => {
  const clearAuthToken = usePageStateStore.use.clearAuthToken();
  const getDefaultSidebarState = () => window.innerWidth > 768;
  const [isSidebarOpen, setIsSidebarOpen] = useState(getDefaultSidebarState);

  const getInitialDarkMode = () => {
    const savedMode = localStorage.getItem("darkMode");
    if (savedMode !== null) {
      return savedMode === "true";
    }
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  };

  const [darkMode, setDarkMode] = useState(getInitialDarkMode);

  const handleSignOut = () => {
    clearAuthToken();
    // navigate('/');
    window.location.href = import.meta.env.VITE_PAYTRIE_LANDING_PAGE;
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem("darkMode", newMode.toString());
  };

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  useEffect(() => {
    const syncDarkModeAcrossTabs = (event: StorageEvent) => {
      if (event.key === "darkMode") {
        setDarkMode(event.newValue === "true");
      }
    };
    window.addEventListener("storage", syncDarkModeAcrossTabs);
    return () => {
      window.removeEventListener("storage", syncDarkModeAcrossTabs);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        className="fixed top-0 left-0 w-full bg-cloud dark:bg-pebble dark:text-white shadow-md z-[1100] flex items-center justify-between px-4 py-2 h-16 sm:h-16 md:h-12 lg:h-12"
        style={{
          boxShadow:
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
          filter: "drop-shadow(0 4px 6px rgba(255, 255, 255, 0.1))",
        }}
      >
        <div className="flex-1 flex items-center">
          <button
            onClick={toggleSidebar}
            className="bg-cloud dark:bg-pebble p-1 rounded-md flex items-center justify-center w-10 h-10 ml-0"
          >
            {isSidebarOpen ? (
              <img
                src={CollapseIcon}
                alt="Collapse Icon"
                className="w-9 h-9 dark:invert"
              />
            ) : (
              <img
                src={ExpandIcon}
                alt="Expand Icon"
                className="w-9 h-9 dark:invert"
              />
            )}
          </button>
          <img
            src={PaytrieFullIcon}
            alt="Paytrie Logo"
            className="h-12 dark:invert"
          />
        </div>
        <div className="flex-1 flex justify-end">
          <button
            onClick={toggleDarkMode}
            className="flex items-center justify-center w-10 h-10 p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 outline-none focus:outline-none"
          >
            {darkMode ? (
              <SunIcon className="w-6 h-6 text-white" />
            ) : (
              <MoonIcon className="w-6 h-6 text-black dark:invert" />
            )}
          </button>
          <button
            onClick={handleSignOut}
            className="bg-cloud dark:bg-pebble text-black dark:text-white font-bold py-1 px-3 rounded hover:bg-gray-200 dark:hover:bg-gray-700"
          >
            Sign Out
          </button>
        </div>
      </div>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </>
  );
};

export default Topbar;
