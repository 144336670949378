import { useProfileStore } from "@/reducers";
import { ProfileField } from "./ProfileField";

export const InteracInfo = () => {
  const { interacETransfer } = useProfileStore();

  return (
    <article className="grid grid-cols-1 md:grid-cols-2 gap-5">
      <ProfileField
        label="Interac e-Transfer Question"
        value={interacETransfer.question}
        className="md:col-span-2"
      />
      <ProfileField
        label="Interac e-Transfer Answer"
        value={interacETransfer.answer}
        className="md:col-span-2"
      />
    </article>
  );
};
