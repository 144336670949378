export const FormStates = {
  EMAIL: "EMAIL",
  VERIFY_EMAIL: "VERIFY_EMAIL",
  PASSWORD: "PASSWORD",
  PHONE: "PHONE",
  VERIFY_PHONE: "VERIFY_PHONE",
  DISCLOSURE: "DISCLOSURE",
  PERSONAL_INFO: "PERSONAL_INFO",
  OCCUPATION: "OCCUPATION",
  KYC: "KYC",
  KYC_GENERATE_QUESTIONS: "KYC_GENERATE_QUESTIONS",
  COMPLETE: "COMPLETE",
};

export type StateValue = (typeof FormStates)[keyof typeof FormStates];

export class FormWizard {
  private currentState: StateValue;
  private transitions: Record<StateValue, StateValue>;
  private reverseTransitions: Record<StateValue, StateValue>;

  constructor() {
    this.currentState = FormStates.EMAIL;
    this.transitions = {
      //[FormStates.KYC]: FormStates.EMAIL,
      [FormStates.EMAIL]: FormStates.VERIFY_EMAIL,
      [FormStates.VERIFY_EMAIL]: FormStates.PHONE,
      [FormStates.PHONE]: FormStates.VERIFY_PHONE,
      [FormStates.VERIFY_PHONE]: FormStates.DISCLOSURE,
      [FormStates.DISCLOSURE]: FormStates.PERSONAL_INFO,
      [FormStates.PERSONAL_INFO]: FormStates.OCCUPATION,
      [FormStates.OCCUPATION]: FormStates.KYC_GENERATE_QUESTIONS,
      [FormStates.KYC_GENERATE_QUESTIONS]: FormStates.PASSWORD,
      [FormStates.PASSWORD]: FormStates.COMPLETE,
    };
    this.reverseTransitions = this.reverse_transitions();
  }

  getCurrentState() {
    return this.currentState;
  }

  private setCurrentState(state: StateValue) {
    this.currentState = state;
  }

  public next() {
    if (this.currentState !== FormStates.COMPLETE) {
      this.currentState = this.transitions[this.currentState];
    }
  }

  private reverse_transitions = () =>
    Object.keys(this.transitions).reduce(
      (acc: Record<StateValue, StateValue>, key: StateValue) => {
        const reverseKey = this.transitions[key];
        acc[reverseKey] = key;
        return acc;
      },
      {}
    );

  public back() {
    this.currentState = this.reverseTransitions[this.currentState];
  }

  public jumpTo(page: StateValue) {
    this.setCurrentState(page);
  }

  public reset() {
    this.setCurrentState(FormStates.EMAIL);
  }

  public complete() {
    this.setCurrentState(FormStates.COMPLETE);
  }
}

export const FormManager = new FormWizard();
