import { ReactNode } from "react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import * as SelectPrimitive from "@radix-ui/react-select";

type SelectProps = {
  children: ReactNode;
} & SelectPrimitive.SelectProps;

export const Select = ({ children, ...props }: SelectProps) => {
  return (
    <SelectPrimitive.Root {...props}>
      <SelectPrimitive.Trigger className="bg-white dark:bg-black border border-pebble dark:border-white p-1.5 flex gap-2 items-center justify-between w-full">
        <SelectPrimitive.Value placeholder="Select one" />
        <SelectPrimitive.Icon>
          <ChevronDownIcon />
        </SelectPrimitive.Icon>
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Portal>
        <SelectPrimitive.Content
          className="bg-white dark:bg-black border border-pebble dark:border-white"
          position="popper"
          sideOffset={5}
        >
          <SelectPrimitive.ScrollUpButton>
            <ChevronUpIcon />
          </SelectPrimitive.ScrollUpButton>
          <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
          <SelectPrimitive.ScrollDownButton>
            <ChevronDownIcon />
          </SelectPrimitive.ScrollDownButton>
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  );
};

Select.displayName = "Select";

type SelectItemProps = {
  children: ReactNode;
} & SelectPrimitive.SelectItemProps;

export const SelectItem = ({ children, ...props }: SelectItemProps) => {
  return (
    <SelectPrimitive.Item
      {...props}
      className="p-2 hover:bg-neutral-300 dark:hover:bg-slate-700 flex gap-2 items-center"
    >
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      <SelectPrimitive.ItemIndicator>
        <CheckIcon />
      </SelectPrimitive.ItemIndicator>
    </SelectPrimitive.Item>
  );
};

SelectItem.displayName = "SelectItem";
