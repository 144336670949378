import { useState } from "react";
import { ExternalLinkIcon } from "@radix-ui/react-icons";

import { userCancelTransaction } from "@/requests/order";

type TransactionRowProps = {
  index: number;
  transactionId: string;
  status: string;
  amount: number;
  date: string;
  wallet: string;
  rightSideLabel: string;
  rightSideValue: string;
  leftSideValue: string;
  leftSideLabel: string;
  paymentId: string;
  refreshTransactions: () => void;
};

const statusColor = (status: string) => {
  const lowerStatus = status.toLowerCase();
  if (lowerStatus.includes("completed"))
    return "text-green-500 border-green-500";
  if (lowerStatus.includes("expired")) return "text-red-500 border-red-500";
  if (lowerStatus.includes("processing"))
    return "text-yellow-500 border-yellow-500";
  if (lowerStatus.includes("sending funds"))
    return "text-yellow-500 border-yellow-500";
  if (lowerStatus.includes("cancelled")) return "text-red-500 border-red-500";
  if (lowerStatus.includes("waiting"))
    return "text-yellow-500 border-yellow-500";
  if (lowerStatus.includes("pending"))
    return "text-yellow-500 border-yellow-500";
  return "text-yellow-500 border-yellow-500";
};

const getStatusText = (status: string) => {
  const lowerStatus = status.toLowerCase();
  if (lowerStatus.includes("complete")) return "Completed";
  if (lowerStatus.includes("processing request money transfer"))
    return "Processing";
  if (lowerStatus.includes("expired")) return "Expired";
  if (lowerStatus.includes("cancelled")) return "Cancelled";
  if (lowerStatus.includes("sending funds")) return "Sending Funds";
  if (lowerStatus.includes("waiting")) return "Waiting";
  if (lowerStatus.includes("pending")) return "Pending";
  return status;
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  return date.toLocaleDateString("en-US", options);
};

const getConfirmationUrl = (leftSideLabel: string, rightSideLabel: string) => {
  const labels = [leftSideLabel, rightSideLabel];
  if (
    labels.some((label) =>
      ["USDT-BSC", "BUSD-BSC", "USDC-BSC", "USDT-B", "BUSD-B"].includes(label)
    )
  ) {
    return "https://bscscan.com/";
  } else if (
    labels.some((label) =>
      ["USDC-POLY", "CADC-POLY", "USDC-P", "CADC-P"].includes(label)
    )
  ) {
    return "https://polygonscan.com/";
  } else if (labels.includes("USDC-ARB")) {
    return "https://arbiscan.io/";
  } else if (labels.includes("USDC-OP")) {
    return "https://optimistic.etherscan.io/";
  } else if (labels.includes("USDC-AVAX")) {
    return "https://snowtrace.io/";
  } else if (labels.includes("USDC-FTM")) {
    return "https://ftmscan.com/";
  } else if (labels.includes("USDC-GNO")) {
    return "https://gnosisscan.io/";
  } else if (labels.includes("USDC-CELO")) {
    return "https://celoscan.io/";
  } else if (labels.includes("USDC-SX")) {
    return "https://explorer.sx.technology/";
  } else if (labels.includes("USDC-BASE")) {
    return "https://basescan.org/";
  } else if (labels.includes("USDC-SOL")) {
    return "https://solscan.io/";
  } else {
    return "https://etherscan.io/";
  }
};

const truncateMiddle = (str: string, maxLength: number) => {
  if (str.length <= maxLength) return str;
  const halfLength = Math.floor((maxLength - 3) / 2);
  return (
    str.substring(0, halfLength) +
    "..." +
    str.substring(str.length - halfLength)
  );
};

const getNetwork = (leftSideLabel: string, rightSideLabel: string) => {
  const labels = [leftSideLabel, rightSideLabel];
  if (
    labels.some((label) =>
      ["USDT-BSC", "BUSD-BSC", "USDC-BSC", "USDT-B", "BUSD-B"].includes(label)
    )
  ) {
    return "Binance Smart Chain";
  } else if (
    labels.some((label) =>
      ["USDC-POLY", "CADC-POLY", "USDC-P", "CADC-P"].includes(label)
    )
  ) {
    return "Polygon";
  } else if (labels.includes("USDC-ARB")) {
    return "Arbitrum";
  } else if (labels.includes("USDC-OP")) {
    return "Optimism";
  } else if (labels.includes("USDC-AVAX")) {
    return "Avalanche";
  } else if (labels.includes("USDC-FTM")) {
    return "Fantom";
  } else if (labels.includes("USDC-GNO")) {
    return "Gnosis";
  } else if (labels.includes("USDC-CELO")) {
    return "Celo";
  } else if (labels.includes("USDC-SX")) {
    return "SX Network";
  } else if (labels.includes("USDC-BASE")) {
    return "Base";
  } else if (labels.includes("USDC-SOL")) {
    return "Solana";
  } else {
    return "Ethereum";
  }
};

export const TransactionRow = ({
  index,
  transactionId,
  status,
  amount,
  date,
  wallet,
  rightSideLabel,
  rightSideValue,
  leftSideLabel,
  leftSideValue,
  paymentId,
  refreshTransactions,
}: TransactionRowProps) => {
  const [isCancelConfirmVisible, setIsCancelConfirmVisible] = useState(false);

  const handleCancelTransaction = async () => {
    try {
      const response = await userCancelTransaction(transactionId);
      if (response.status === 200) {
        refreshTransactions();
      } else {
        console.error("Error cancelling transaction:", response.data);
      }
    } catch (error) {
      console.error("Error cancelling transaction:", error);
    } finally {
      setIsCancelConfirmVisible(false);
    }
  };

  const showCancelButton =
    status.toLowerCase().startsWith("pending") ||
    status.toLowerCase().startsWith("waiting");

  return (
    <>
      <tr className="cursor-pointer" onClick={() => showTXNDetails(index)}>
        <td className="text-sm py-3 sm:py-6 border-b border-gray-300 px-2 break-all">
          {transactionId}
        </td>
        <td className="text-sm py-3 sm:py-6 border-b border-gray-300 text-left break-all">
          <span
            className={`inline-block border ${statusColor(getStatusText(status))} text-center py-2 my-2 break-words whitespace-normal`}
            style={{
              minWidth: "12ch",
              maxWidth: "15ch",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {getStatusText(status)}
          </span>
        </td>
        <td className="text-sm py-3 sm:py-6 border-b border-gray-300 text-right lg:text-center sm:text-center break-all">
          {amount}
        </td>
      </tr>
      <tr id={`panel${index}`} className="hidden bg-cloudDarker">
        <td colSpan={3} className="dark:bg-darkCharcoal py-2 sm:py-4 px-2">
          <div className="text-sm ml-2 p-1 sm:p-2 rounded border-gray-300">
            <div className="flex flex-col sm:flex-row justify-between py-1">
              <strong>Date:</strong> <span>{formatDate(date)}</span>
            </div>
            <div className="flex flex-col sm:flex-row justify-between py-1">
              <strong>Wallet:</strong>
              <span>
                <a
                  href={`${getConfirmationUrl(leftSideLabel, rightSideLabel)}address/${wallet}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block sm:inline break-all whitespace-normal text-blue-500"
                  style={{ wordBreak: "break-word", maxWidth: "42ch" }}
                >
                  {truncateMiddle(wallet, 66)}
                </a>
              </span>
            </div>
            <div className="flex flex-col sm:flex-row justify-between py-1">
              <strong>You pay:</strong>{" "}
              <span>
                {leftSideValue} {leftSideLabel}
              </span>
            </div>
            <div className="flex flex-col sm:flex-row justify-between py-1">
              <strong>You receive:</strong>{" "}
              <span>
                {rightSideValue} {rightSideLabel}
              </span>
            </div>
            <div className="flex flex-col sm:flex-row justify-between py-1">
              <strong>Network:</strong>
              <span>{getNetwork(leftSideLabel, rightSideLabel)}</span>
            </div>
            <div className="flex flex-col sm:flex-row justify-between py-1">
              <strong>Status:</strong>
              <span>
                {getStatusText(status)}
                <span>
                  <a
                    href={`/status?id=${transactionId}`}
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                    target="_blank"
                  >
                    <ExternalLinkIcon className="inline-block w-5 h-5" />
                  </a>
                </span>
              </span>
            </div>
            <div className="flex flex-col sm:flex-row justify-between py-1">
              <strong>Tx link:</strong>
              <span>
                <a
                  href={`${getConfirmationUrl(leftSideLabel, rightSideLabel)}tx/${paymentId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block sm:inline break-all whitespace-normal text-blue-500"
                  style={{ wordBreak: "break-word", maxWidth: "42ch" }}
                >
                  {truncateMiddle(paymentId, 66)}
                </a>
              </span>
            </div>
            {showCancelButton && (
              <div className="flex justify-start mb-0">
                <button
                  className="bg-black border dark:border-black dark:bg-white dark:text-black text-white py-2 px-4 mt-3"
                  onClick={() => setIsCancelConfirmVisible(true)}
                >
                  Cancel Transaction
                </button>
              </div>
            )}
          </div>
        </td>
      </tr>

      {isCancelConfirmVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-70 z-40"></div>
          <div
            className="relative bg-white dark:bg-pebble p-6 shadow-lg text-center z-50 flex flex-col"
            style={{ width: "400px", height: "200px" }}
          >
            <h2 className="text-lg font-bold mb-4 mt-8">
              Cancel this transaction?
            </h2>
            <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-6 mb-5">
              <button
                className="bg-black dark:bg-white text-white dark:text-black py-1 px-6"
                onClick={handleCancelTransaction}
              >
                Yes
              </button>
              <button
                className="bg-white dark:bg-pebble text-black dark:text-white py-1 px-6 border border-black dark:border-white"
                onClick={() => setIsCancelConfirmVisible(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function showTXNDetails(index: number) {
  const panel = document.getElementById(`panel${index}`);
  if (panel) {
    panel.classList.toggle("hidden");
  }
}

export default TransactionRow;
