import {
  InteracLabel,
  LoginHeader,
  PoweredByLabel,
} from "@/components/widget-components";
import { SignupPanel } from "@/panels/signup/SignupPanel";
import { Stepper } from "./Stepper";

const isIframe = window.self !== window.top;

export const Signup = () => {
  const Footer = () => {
    return (
      <div
        className="absolute left-0 right-0 flex justify-center p-4 sm:bottom-0"
        style={{
          top: isIframe
            ? window.innerWidth < 640
              ? "526px"
              : "539px"
            : window.innerWidth < 640
              ? "526px"
              : "660px",
        }}
      >
        <PoweredByLabel />
      </div>
    );
  };

  return (
    <div className="flex flex-col md:flex-row gap-4 max-w-[730px] lg:min-w-[730px] mx-auto min-w-full relative">
      <LoginHeader>
        <Stepper />
      </LoginHeader>
      <div className="flex flex-col flex-grow relative">
        <SignupPanel />
        <Footer />
      </div>
    </div>
  );
};
