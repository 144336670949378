import { ReactNode } from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";

export const Checkbox = ({
  label,
  id,
  ...props
}: CheckboxPrimitive.CheckboxProps & { label: ReactNode }) => {
  return (
    <div className="flex items-center">
      <CheckboxPrimitive.Root
        {...props}
        className="border-black dark:border-cloud border-2 border-solid h-[25px] w-[25px] flex items-center justify-center min-w-[25px] focus:outline-clementine dark:focus:outline-sun outline-offset-4"
        id={id}
      >
        <CheckboxPrimitive.Indicator className="dark:text-cloud">
          <CheckIcon />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      <label className="pl-[15px] text-[15px] leading-none" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
