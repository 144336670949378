import { CopyIcon } from "@radix-ui/react-icons";

import { useProfileStore } from "@/reducers";
import { ProfileField } from "./ProfileField";
import { useState, useEffect } from "react";

export const ReferralInfo = () => {
  const { referral } = useProfileStore();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const updateIsMobile = () => setIsMobile(window.innerWidth <= 768);
    updateIsMobile();
    window.addEventListener("resize", updateIsMobile);
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const handleCopy = () => {
    if (referral.link) {
      navigator.clipboard
        .writeText(referral.link)
        .catch(() => console.error("Failed to copy"));
    }
  };

  const formatLink = (link: string, maxLength: number) => {
    if (!link) return "";
    return (
      link
        .replace(/\s+/g, "")
        .match(new RegExp(`.{1,${maxLength}}`, "g"))
        ?.join("\n") || ""
    );
  };

  return (
    <article className="grid gap-2">
      <div className="flex items-center justify-between">
        <ProfileField
          label="Referral Code"
          value={referral.code}
          className="w-full"
        />
      </div>
      <div className="flex items-center justify-between">
        <ProfileField
          label="Referral Credits"
          value={referral.credits?.toString()}
          className="w-full"
        />
      </div>
      <div className="relative flex flex-col items-start">
        <ProfileField
          label="Referral Link"
          value={formatLink(referral.link, isMobile ? 11 : 24)}
          className="w-full whitespace-pre-wrap break-words"
        />
        <button
          onClick={handleCopy}
          className="absolute right-0 p-1 mt-2 text-blue-500 hover:text-blue-700 dark:text-blue-300 dark:invert dark:hover:text-blue-500 flex items-center"
        >
          <CopyIcon className="w-5 h-5 dark:invert" />
        </button>
      </div>
    </article>
  );
};

export default ReferralInfo;
