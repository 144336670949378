import { createSelectorFunctions } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { FormManager, StateValue } from "@/panels/signup/FormStateMachine";

type SignupState = {
  formState: StateValue;
  email: string;
  verificationCode: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  birthDay: string;
  address: {
    streetAddress1: string;
    streetAddress2: string;
    province: string | undefined;
    postalCode: string;
    city: string;
  };
  employmentStatus: string | undefined;
  occupation: string | undefined;
  occupationDetails: string;
  purpose: string | undefined;
  verifyId: string;
  sessionId: string;
  referralCode: string;
  oliuVerifyType: string;
  oliuPhoneVerified: boolean;
};

const initialState: SignupState = {
  formState: FormManager.getCurrentState(),
  email: "",
  verificationCode: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  birthDay: "",
  address: {
    streetAddress1: "",
    streetAddress2: "",
    province: localStorage.getItem("province") || undefined,
    postalCode: "",
    city: "",
  },
  employmentStatus: localStorage.getItem("employmentStatus") || undefined,
  occupation: localStorage.getItem("occupation") || undefined,
  occupationDetails: "",
  purpose: localStorage.getItem("purpose") || undefined,
  verifyId: "",
  sessionId: "",
  referralCode: "",
  oliuVerifyType: "",
  oliuPhoneVerified: sessionStorage.getItem("oliuPhoneVerified") === "true",
};

type Actions = {
  setEmail: (email: string) => void;
  setPhoneNumber: (email: string) => void;
  setFirstName: (email: string) => void;
  setLastName: (email: string) => void;
  setBirthDay: (birthday: string) => void;
  setAddress1: (address: string) => void;
  setAddress2: (address: string) => void;
  setPostalCode: (postalCode: string) => void;
  setCity: (city: string) => void;
  setProvince: (province: string) => void;
  setOccupation: (occupation: string | undefined) => void;
  setOccupationDetails: (occupationDetails: string) => void;
  setEmploymentStatus: (employmentStatus: string) => void;
  setPurpose: (purpose: string) => void;
  setVerificationCode: (code: string) => void;
  setOliuVerifyId: (verifyId: string) => void;
  setSessionId: (sessionId: string) => void;
  setReferralCode: (referralCode: string) => void;
  setOliuVerifyType: (oliuVerifyType: string) => void;
  setOliuPhoneVerified: (oliuPhoneVerified: boolean) => void;
  nextState: () => void;
  prevState: () => void;
  jumpTo: (page: StateValue) => void;
  complete: () => void;
  reset: () => void;
};

const createSignupStore = create<SignupState & Actions>()(
  immer<SignupState & Actions>((set) => ({
    ...initialState,
    setEmail: (email: string) =>
      set((state) => {
        state.email = email;
      }),
    setPhoneNumber: (phoneNumber: string) =>
      set((state) => {
        state.phoneNumber = phoneNumber;
      }),
    setFirstName: (firstName: string) =>
      set((state) => {
        state.firstName = firstName;
      }),
    setLastName: (lastName: string) =>
      set((state) => {
        state.lastName = lastName;
      }),
    setBirthDay: (birthDay: string) =>
      set((state) => {
        state.birthDay = birthDay;
      }),
    setAddress: (address: SignupState["address"]) =>
      set((state) => {
        state.address = address;
      }),
    setOliuVerifyId: (verifyId: string) =>
      set((state) => {
        state.verifyId = verifyId;
      }),
    setSessionId: (sessionId: string) =>
      set((state) => {
        state.sessionId = sessionId;
      }),
    setReferralCode: (referralCode: string) =>
      set((state) => {
        state.referralCode = referralCode;
      }),
    nextState: () =>
      set((state) => {
        FormManager.next();
        state.formState = FormManager.getCurrentState();
      }),
    prevState: () =>
      set((state) => {
        FormManager.back();
        state.formState = FormManager.getCurrentState();
      }),
    complete: () =>
      set((state) => {
        FormManager.complete();
        state.formState = FormManager.getCurrentState();
      }),
    jumpTo: (page: StateValue) =>
      set((state) => {
        FormManager.jumpTo(page);
        state.formState = FormManager.getCurrentState();
      }),
    setOccupation: (occupation: string | undefined) => {
      set((state) => {
        if (state.occupation !== null) {
          state.occupation = occupation;
        }
      });
    },
    setOccupationDetails: (occupationDetails: string) => {
      set((state) => {
        if (state.occupationDetails !== null) {
          state.occupationDetails = occupationDetails;
        }
      });
    },
    setEmploymentStatus: (employmentStatus: string) => {
      set((state) => {
        if (state.employmentStatus !== null) {
          state.employmentStatus = employmentStatus;
        }
      });
    },
    setPurpose: (purpose: string) => {
      set((state) => {
        if (state.purpose !== null) {
          state.purpose = purpose;
        }
      });
    },
    setAddress1: (address: string) =>
      set((state) => {
        state.address.streetAddress1 = address;
      }),
    setAddress2: (address: string) =>
      set((state) => {
        state.address.streetAddress2 = address;
      }),
    setPostalCode: (postalCode: string) =>
      set((state) => {
        state.address.postalCode = postalCode;
      }),
    setProvince: (province: string) =>
      set((state) => {
        state.address.province = province;
      }),
    setCity: (city: string) =>
      set((state) => {
        state.address.city = city;
      }),
    setVerificationCode: (code: string) =>
      set((state) => {
        state.verificationCode = code;
      }),
    setOliuVerifyType: (oliuVerifyType: string) =>
      set((state) => {
        state.oliuVerifyType = oliuVerifyType;
      }),
    setOliuPhoneVerified: (oliuPhoneVerified: boolean) =>
      set((state) => {
        state.oliuPhoneVerified = oliuPhoneVerified;
      }),
    reset: () => {
      set(() => {
        FormManager.reset();
        return initialState;
      });
    },
  }))
);

export const useSignupStore = createSelectorFunctions(createSignupStore);
