import { ChangeEvent, useRef } from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input`
  display: flex;
  padding: 0px;
  width: 100%;
  margin-top: 10px;
  padding-bottom: 4px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--rock);
  height: 32px;
  font-family: "PP Object Sans";
  font-size: 16px;
  text-align: center;

  &:focus {
    outline: none;
    border-bottom-color: var(--clementine);
    & + label {
      top: -10px;
      font-size: 12px;
      color: var(--clementine);
    }
  }

  &:not(:placeholder-shown) + label,
  &:focus + label {
    top: -2px;
    font-size: 12px;
    color: var(--clementine);
  }

  .dark & {
    &:focus {
      border-bottom-color: var(--sun);
      & + label {
        color: var(--sun);
      }
    }
    &:not(:placeholder-shown) + label,
    &:focus + label {
      color: var(--sun);
    }
  }
`;

const Label = styled.label`
  position: absolute;
  left: 0;
  top: 10px; /* Initial position */
  transition: all 0.2s ease-in-out; /* Smooth transition */
  color: var(--rock);
`;

interface BirthdayInputProps {
  value: string;
  onChange: (date: string) => void;
}

export const BirthdayInput = ({ value, onChange }: BirthdayInputProps) => {
  const yearRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);

  const dateParts = value ? value.split("-") : ["", "", ""];
  const [storedYear, storedMonth, storedDay] = dateParts;

  const updateFullDate = (year: string, month: string, day: string) => {
    const cleanYear = year.replace(/\D/g, "");
    const cleanMonth = month.replace(/\D/g, "");
    const cleanDay = day.replace(/\D/g, "");

    if (cleanYear || cleanMonth || cleanDay) {
      onChange(`${cleanYear}-${cleanMonth}-${cleanDay}`);
    } else {
      onChange("");
    }
  };

  const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentYear = new Date().getFullYear();
    let newYear = e.target.value.replace(/\D/g, "").slice(0, 4);

    const yearNum = parseInt(newYear);

    if (
      newYear === "" ||
      newYear.length < 4 ||
      (yearNum >= 1900 && yearNum <= currentYear + 1)
    ) {
      updateFullDate(newYear, storedMonth, storedDay);

      if (newYear.length === 4) {
        monthRef.current?.focus();
      }
    } else {
      newYear = storedYear;
    }
  };

  const handleMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newMonth = e.target.value.replace(/\D/g, "").slice(0, 2);
    const monthNum = parseInt(newMonth);

    if (newMonth.length === 1) {
      if (monthNum > 1) {
        newMonth = `0${newMonth}`;
      }
    } else if (newMonth.length === 2) {
      if (monthNum < 1 || monthNum > 12) {
        newMonth = storedMonth;
      } else {
        dayRef.current?.focus();
      }
    }

    updateFullDate(storedYear, newMonth, storedDay);
  };

  const handleDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newDay = e.target.value.replace(/\D/g, "").slice(0, 2);
    const dayNum = parseInt(newDay);

    if (newDay.length === 1) {
      if (dayNum > 3) {
        newDay = `0${newDay}`;
      }
    } else if (newDay.length === 2) {
      const monthNum = parseInt(storedMonth);
      const maxDays = getMaxDaysInMonth(monthNum, parseInt(storedYear));

      if (dayNum < 1 || dayNum > maxDays) {
        newDay = storedDay;
      }
    }

    updateFullDate(storedYear, storedMonth, newDay);
  };

  const getMaxDaysInMonth = (month: number, year: number): number => {
    if (isNaN(month) || month < 1 || month > 12) return 31;

    if (month === 2) {
      if ((year && year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        return 29;
      }
      return 28;
    }

    if ([1, 3, 5, 7, 8, 10, 12].includes(month)) return 31;

    return 30;
  };

  return (
    <InputContainer>
      <InputWrapper>
        <StyledInput
          ref={yearRef}
          type="text"
          inputMode="numeric"
          placeholder=" "
          value={storedYear}
          onChange={handleYearChange}
        />
        <Label>YYYY</Label>
      </InputWrapper>
      <InputWrapper>
        <StyledInput
          ref={monthRef}
          type="text"
          inputMode="numeric"
          placeholder=" "
          value={storedMonth}
          onChange={handleMonthChange}
        />
        <Label>MM</Label>
      </InputWrapper>
      <InputWrapper>
        <StyledInput
          ref={dayRef}
          type="text"
          inputMode="numeric"
          placeholder=" "
          value={storedDay}
          onChange={handleDayChange}
        />
        <Label>DD</Label>
      </InputWrapper>
    </InputContainer>
  );
};
