import { ChangeEvent } from "react";
import { UploadIcon } from "@radix-ui/react-icons";

import Upload from "@/assets/upload-arrow-tray.svg";
import { ImageData } from "@/requests/signup";
import { convertBase64 } from "./utils";

type FileUploaderProps = {
  onUploadComplete: (fileData: ImageData) => void;
  onError: (error: string) => void;
  customButtonText: string;
  showUploadIcon: boolean;
  showUploadSVG?: boolean;
};

export const FileUploader = ({
  onUploadComplete,
  onError,
  customButtonText,
  showUploadIcon,
  showUploadSVG = false,
}: FileUploaderProps) => {
  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const selectedFiles = files as FileList;
    const fileName = e.target.value;
    try {
      const base64 = await convertBase64(selectedFiles[0]);
      const fileType = `image/${fileName.substring(
        fileName.lastIndexOf(".") + 1
      )}`;
      onUploadComplete({
        name: fileName,
        type: fileType,
        url: base64 as string,
      });
    } catch {
      onError("Unable to read image. Please check and try again.");
    }
  };
  return (
    <label className="cursor-pointer">
      <input
        className="invisible"
        type="file"
        accept=".pdf,.png,.jpg,.jpeg"
        onChange={handleUpload}
      />

      <span className="uppercase flex flex-col justify-center items-center gap-2 p-5 bg-neutral-300 dark:bg-gravel hover:bg-neutral-200 dark:hover:bg-gray-800">
        {showUploadSVG && (
          <img src={Upload} alt="Upload Icon" className="w-7 h-7 dark:invert" />
        )}
        {customButtonText.length > 0 ? customButtonText : "Upload"}
        {showUploadIcon && <UploadIcon />}
      </span>
    </label>
  );
};
