import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PanelLayout } from "./PanelLayout";
import { verifyEmail } from "@/requests/login";
import { usePageStateStore } from "@/reducers";

export default function LoginVerifyPanel() {
  const queryParameters = new URLSearchParams(window.location.search);
  const email = queryParameters.get("email") || "";
  const id = queryParameters.get("id") || "";
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");
  const [countdown, setCountdown] = useState(10);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const setAuthToken = usePageStateStore.use.setAuthToken();
  const setLoggedInUser = usePageStateStore.use.setEmail();

  useEffect(() => {
    async function callVerifyEmail(email: string, id: string) {
      const { response, errorMessage } = await verifyEmail(email, id);

      if (response && response.data.status === "success") {
        setTitle("You are now logged in");
        setStatus(
          "Please return to the previous page to continue or redirecting to app in 10"
        );
        setSuccess(true);
        const authToken = response.data.token;
        if (authToken) {
          setAuthToken(authToken);
          setLoggedInUser(email);
          const intervalId = setInterval(() => {
            setCountdown((prev) => {
              if (prev === 1) {
                clearInterval(intervalId);
                navigate("/");
              }
              return prev - 1;
            });
          }, 1000);
          return () => clearInterval(intervalId);
        }
      } else if (
        errorMessage &&
        (errorMessage === "This link has expired" ||
          errorMessage === "Link expired. Link is only valid for 5 minutes" ||
          errorMessage === "Already verified")
      ) {
        setTitle("SIGN IN LINK EXPIRED");
        setStatus(
          "This sign-in link has expired. Please request a new one to continue."
        );
      } else {
        setTitle("");
        setStatus(
          "Something went wrong during sign-in. Please try again or contact support@paytrie.com if the issue persists."
        );
      }
    }
    // Call function to secureVerify
    //
    callVerifyEmail(email, id);
  }, [email, id, setAuthToken, setLoggedInUser, navigate]);

  return (
    <PanelLayout>
      <div className="flex flex-col gap-5">
        <h2 className="text-base font-bold uppercase">{title}</h2>
        <div className="text-base">
          {success ? status.replace("10", countdown.toString()) : status}
        </div>
        {success && (
          <div className="text-base mt-2">
            If not redirected, please click{" "}
            <a
              href={import.meta.env.VITE_APPLICATION_URL}
              className="text-blue-600 underline"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </div>
        )}
      </div>
    </PanelLayout>
  );
}
