import { useState, useMemo } from "react";
import * as Dialog from "@radix-ui/react-dialog";

import { InputText } from "@/components/widget-components";
import { useBuySellStore } from "@/reducers/transactionReducer";
import {
  NetworkValue,
  NetworkOptions,
  supportedCurrencies,
} from "@/utils/currencies";

type NetworkProps = {
  onSelectNetwork: (network: NetworkValue) => void;
};

//export const NetworkItems = ({}) => {
export const NetworkItems = ({ onSelectNetwork }: NetworkProps) => {
  const [searchText, setSearchText] = useState("");
  const stableCoin = useBuySellStore.use.stableCoin();

  const filteredOptions = useMemo(() => {
    return Object.entries(NetworkOptions).filter(
      ([networkValue, networkOption]) => {
        return (
          networkOption.name.toLowerCase().includes(searchText.toLowerCase()) &&
          supportedCurrencies[networkValue as NetworkValue].includes(stableCoin)
        );
      }
    );
  }, [searchText]);
  console.log("FILTEREDOPTIONS:", filteredOptions);

  return (
    <div>
      <div
        className="text-l font-bold items-center"
        style={{ textAlign: "center" }}
      >
        Select Network
      </div>
      <InputText
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />
      <div className="flex flex-col items-start mt-2">
        {filteredOptions.map(([key, option]) => {
          //const clickedKey: NetworkValue = NetworkOptions[key];
          return (
            <Dialog.Close key={option.name} asChild>
              <div
                //onClick={() => setNetwork(key as NetworkValue)}
                onClick={() => onSelectNetwork(key as NetworkValue)}
                //onClick={(e) => console.log("KEY", e.target.innerText)}
                className="p-4 hover:border-px hover:bg-slate-200 hover:text-black dark:hover:text-black text-black dark:text-white"
              >
                <div className="flex gap-1 justify-left items-center md:w-[16rem]">
                  {option.icon && (
                    <div className="mr-1 md:mr-2">
                      <img src={option.icon} height={20} width={20} />
                    </div>
                  )}
                  {option.name}
                </div>
              </div>
            </Dialog.Close>
          );
        })}
      </div>
    </div>
  );
};
