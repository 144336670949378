import { useState, useEffect } from "react";
import { AxiosError, isAxiosError } from "axios";
import * as EmailValidator from "email-validator";
import * as Toast from "@radix-ui/react-toast";
import { useNavigate, useLocation } from "react-router-dom";

import { Button } from "@/components/Button/Button";
import { LoadingIcon } from "@/components/LoadingIcon";
import { NoAccountText, TextInput } from "@/components/widget-components";
import { usePageStateStore } from "@/reducers";
import {
  completePasswordlessLogin,
  triggerPasswordlessLogin,
} from "@/requests/login";
import { PanelLayout } from "./PanelLayout";

export default function EmailLoginPanel({ landing }: { landing: boolean }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [nonce, setNonce] = useState(Date.now());
  const [count, setCount] = useState(0);
  const [waitingForApproval, setWaitingForApproval] = useState(false);
  const setAuthToken = usePageStateStore.use.setAuthToken();
  const setLoggedInUser = usePageStateStore.use.setEmail();
  const [codeSentSuccess, setCodeSentSuccess] = useState(true);
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = landing
    ? "/Dashboard"
    : location.state?.from?.pathname || "/Transactions";

  // effect needs to call method to do api call on dependecy changes of values: count ,email and waitingOnApproval
  useEffect(() => {
    async function handleEasyLogin(email: string, nonce: number) {
      const { response, error } = await completePasswordlessLogin(email, nonce);
      if (error) {
        setError("An unexpected error has occured.  Please try again later");
      } else if (response) {
        if (response.data.status) {
          setAuthToken(response.data.token);
          setLoggedInUser(email);
          navigate(redirectTo, { replace: true });
        } else {
          setTimeout(() => {
            setCount((count) => count + 1);
          }, 5000);
        }
      }
    }

    if (count < 24 && waitingForApproval) {
      // call api
      handleEasyLogin(email, nonce);
    }
  }, [
    count,
    email,
    nonce,
    waitingForApproval,
    navigate,
    setAuthToken,
    setLoggedInUser,
    redirectTo,
  ]);

  const triggerLogin = async (overideNonce?: number) => {
    if (!email?.length || !EmailValidator.validate(email)) {
      setError("Please enter a valid email");
      return;
    }
    setLoading(true);
    triggerPasswordlessLogin(email, overideNonce || nonce)
      .then(() => {
        setCodeSentSuccess(true);
        setWaitingForApproval(true);
      })
      .catch((errorResponse) => {
        //setLoading(false);
        //setCodeSentSuccess(false);
        // non 200 response from server
        if (isAxiosError(errorResponse)) {
          const axiosError = errorResponse as AxiosError;
          if (axiosError.response && axiosError.response.status < 500) {
            setCodeSentSuccess(true);
            setWaitingForApproval(true);
            // setError(
            //   'A user account associated with this email address was not found'
            // );
          } else {
            setLoading(false);
            setCodeSentSuccess(false);
            setError("Unable to login at this time. Please try again later");
          }
        }
      });
  };
  if (waitingForApproval) {
    return (
      <PanelLayout>
        <div className="flex flex-col gap-5">
          {/* <div className="text-base">
            Please check your email for verification link.
          </div>

          <div className="text-base">
            The verification link will expire in 5 mins.
          </div>

          <div className="text-base" style={{ color: 'red' }}>
            Do not close this page
          </div>
          <div className="text-base">
            This page will load when verification link is authenticated
          </div> */}
          <h2 className="text-lg font-bold uppercase">Check your email</h2>

          <div className="text-base">We've sent a sign in link to {email}.</div>
          <div text-base>
            Keep this page open and return here after clicking the link.
          </div>

          <NoAccountText className="mt-10">
            <Toast.Provider swipeDirection="right">
              <button
                className="codeButton"
                type="button"
                style={{ float: "left", textDecoration: "underline" }}
                onClick={() => {
                  const newNonce = Date.now();
                  setNonce(newNonce);
                  setOpen(true);
                  triggerLogin(newNonce);
                }}
              >
                Resend link
              </button>
              <Toast.Root
                className="ToastRoot"
                open={open}
                onOpenChange={setOpen}
              >
                {codeSentSuccess && (
                  <Toast.Title className="ToastTitle">Email sent</Toast.Title>
                )}
                {!codeSentSuccess && (
                  <Toast.Title className="ToastTitle">
                    Unable to send Email
                  </Toast.Title>
                )}
              </Toast.Root>
              <Toast.Viewport className="ToastViewport" />
            </Toast.Provider>
          </NoAccountText>
        </div>
      </PanelLayout>
    );
  }

  if (loading) {
    return (
      <PanelLayout>
        <div className="flex justify-center" style={{ paddingLeft: "135px" }}>
          <LoadingIcon />
        </div>
      </PanelLayout>
    );
  }

  return (
    <PanelLayout>
      <div className="flex flex-col gap-6 w-full ml-1">
        <h2 className="text-lg font-bold uppercase -mt-4">Sign in</h2>
        <TextInput
          autoFocus
          placeholder="Email"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              triggerLogin();
            }
          }}
          value={email}
        />
        {error && <div className="my-1 text-rose-600">{error}</div>}
        <Button
          disabled={loading}
          onClick={() => triggerLogin()}
          className="inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
        >
          Sign in
        </Button>
        <NoAccountText>
          Don&apos;t have an account?{" "}
          <a
            className="cursor-pointer underline hover:text-clementine dark:hover:text-sun"
            onClick={() => navigate("/Signup")}
          >
            Sign up
          </a>
          .
        </NoAccountText>
      </div>
    </PanelLayout>
  );
}
