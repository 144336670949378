import { ReactNode } from "react";
import { styled } from "styled-components";

import {
  useBuySellStore,
  TransactionType,
} from "@/reducers/transactionReducer";
import { StableCoinValue, StableCoins, Networks } from "@/utils/currencies";

const List = styled.ol`
  display: flex;
  flex-direction: column;
`;

const Entry = styled.li`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  padding-left: 15px;
  position: relative;
  padding-bottom: 10px;
  &:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 21px;
    height: calc(100% - 20px);
    border-right: 1.5px dotted;
    border-color: var(--rock);
  }
  &:after {
    content: "";
    position: absolute;
    left: calc(0px - 8px);
    top: 3px;
    width: 16px;
    height: 16px;
    border: 2.5px solid;
    border-color: var(--rock);
    border-radius: 50%;
    background: #b7b6b6;
  }
  &:last-child {
    &:before {
      display: none;
    }
  }
  &:first-child:after {
    background: #b7b6b6;
  }
`;

const ConversionRow = ({ currency }: { currency: StableCoinValue }) => {
  const quoteEngine = useBuySellStore.use.quoteEngine();
  const network = useBuySellStore.use.network();
  return (
    <div>
      <span className="font-normal leading-3">
        1 {StableCoins[currency as keyof typeof StableCoins]}-{network}
        &nbsp;=&nbsp;
      </span>
      <span className="text-clementine dark:text-sun">
        {quoteEngine?.format(1, currency, 4)} CAD
      </span>
    </div>
  );
};

const Title = ({ children }: { children: ReactNode }) => {
  return <div className="text-clementine dark:text-sun">{children}</div>;
};

const SwapTitle = ({ children }: { children: ReactNode }) => {
  return <div className="text-black dark:text-sun">{children}</div>;
};

const SubTitle = ({ children }: { children: ReactNode }) => {
  return <div className="text-xs dark:text-rock">{children}</div>;
};

export const SubTotal = () => {
  const [stablecoin, quoteEngine, network, transactionType] = [
    useBuySellStore.use.stableCoin(),
    useBuySellStore.use.quoteEngine(),
    useBuySellStore.use.network(),
    useBuySellStore.use.transactionType(),
  ];

  return (
    <List className="my-auto w-full">
      <Entry className="after:bg-cloud after:dark:bg-pebble">
        <div>
          <ConversionRow currency={stablecoin} />
        </div>
      </Entry>
      {transactionType === TransactionType.BUY &&
        network === Networks.ethereum && (
          <Entry>
            <Title>${quoteEngine?.getGasFee(network)}</Title>
            <SubTitle>
              Gas fee (gas price: {quoteEngine?.getGasPrice().toFixed(0)} gwei)
            </SubTitle>
          </Entry>
        )}
      <Entry>
        <SwapTitle>
          Swap fee: 0.{stablecoin === StableCoins.USDC ? 6 : "05"}% (min $
          {stablecoin === StableCoins.CADC ? 2 : 5})
        </SwapTitle>
      </Entry>
    </List>
  );
};
