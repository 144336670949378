import { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as Toast from "@radix-ui/react-toast";
import { Cross2Icon } from "@radix-ui/react-icons";

import { useBuySellStore } from "@/reducers/transactionReducer";

import {
  stableCoinLogos,
  supportedCurrencies,
  StableCoins,
  NetworkValue,
  StableCoinValue,
  Networks,
} from "@/utils/currencies";
import { Tokens } from "../Select/TokenPicker/Tokens";
import { NetworkItems } from "../Select/NetworkItems";

export const WidgetModal = () => {
  const stableCoin = useBuySellStore.use.stableCoin();
  const setStableCoin = useBuySellStore.use.setStableCoin();
  const network = useBuySellStore.use.network();
  const setNetwork = useBuySellStore.use.setNetwork();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [dialogPage1, setDialogPage1] = useState(true);

  const DEFAULT_NETWORK = Networks.ethereum;

  const handleNetworkSelect = (selectedNetwork: NetworkValue): void => {
    // Check if current stableCoin is supported on selected network
    if (!supportedCurrencies[selectedNetwork].includes(stableCoin)) {
      // If the selected network doesn't support the current token,
      // check if there's a network that does support it
      const availableNetworks = Object.entries(supportedCurrencies)
        .filter(([_, tokens]) => tokens.includes(stableCoin))
        .map(([network]) => network as NetworkValue);

      if (availableNetworks.length > 0) {
        // There are networks that support this token
        setNetwork(selectedNetwork);
        setShowToast(true);
        setToastMessage(`${selectedNetwork} doesn't support ${stableCoin}`);
      } else {
        // No networks support this token, fall back to default network and USDC
        setNetwork(DEFAULT_NETWORK);
        const defaultToken: StableCoinValue = StableCoins.USDC;
        setStableCoin(defaultToken);
        setShowToast(true);
        setToastMessage(
          `${stableCoin} isn't supported on ${selectedNetwork}, switching to ${defaultToken} on ${DEFAULT_NETWORK}`
        );
      }
    } else {
      setNetwork(selectedNetwork);
    }
    setDialogPage1(true);
  };

  const handleTokenSelect = (selectedToken: StableCoinValue): void => {
    if (!supportedCurrencies[network].includes(selectedToken)) {
      // Find networks that support this token
      const availableNetworks = Object.entries(supportedCurrencies)
        .filter(([_, tokens]) => tokens.includes(selectedToken))
        .map(([network]) => network as NetworkValue);

      if (availableNetworks.length > 0) {
        // Switch to the first available network that supports this token
        const newNetwork = availableNetworks[0];
        setNetwork(newNetwork);
        setStableCoin(selectedToken);
        setShowToast(true);
        setToastMessage(
          `${selectedToken} isn't supported on ${network}, switching to ${newNetwork}`
        );
      } else {
        // If no networks support this token, keep current network and show error
        setShowToast(true);
        setToastMessage(
          `${selectedToken} isn't supported on any network, please select another token`
        );
        return;
      }
    } else {
      setStableCoin(selectedToken);
    }
    setDialogPage1(false);
  };

  return (
    <Toast.Provider swipeDirection="right" duration={3000}>
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <div className="flex-shrink min-w-min flex items-end mb-2">
            <span style={{ pointerEvents: "none" }}>
              <div className="flex">
                <div className="mr-1 mt-1 w-15">
                  <img
                    src={stableCoinLogos[`${stableCoin}${network}`]}
                    height={40}
                    width={40}
                    alt={`${stableCoin} on ${network}`}
                  />
                </div>
                <div className="text-left flex flex-col justify-center">
                  <div className="flex row font-bold">{stableCoin}</div>
                  <div className="text-sm text-pebble dark:text-white">
                    {network}
                  </div>
                </div>
              </div>
            </span>
            <span style={{ marginLeft: "8px" }}>
              <svg
                style={{ marginBottom: "20px" }}
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
          </div>
        </Dialog.Trigger>

        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-60 z-40" />
          <Dialog.Content
            className="DialogContent bg-white dark:bg-pebble z-50"
            onOpenAutoFocus={(e) => e.preventDefault()}
            style={{
              top: dialogPage1 ? "250px" : "50px",
              transform: "translateX(-50%)",
              overflow: "auto",
            }}
          >
            {dialogPage1 && (
              <div>
                <Dialog.Title className="DialogTitle text-black dark:text-white">
                  <h3 style={{ textAlign: "center" }}>
                    <b>Select Stablecoin</b>
                  </h3>
                </Dialog.Title>
                <Tokens onSelectToken={handleTokenSelect} />
              </div>
            )}

            {!dialogPage1 && (
              <div>
                <NetworkItems onSelectNetwork={handleNetworkSelect} />
              </div>
            )}

            <Dialog.Close asChild>
              <button
                style={{ position: "absolute", top: "10px", left: "10px" }}
                aria-label="Close"
              >
                <Cross2Icon />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <Toast.Root
        className="bg-white dark:bg-slate-800 rounded-md shadow-lg p-4 flex items-center gap-2 fixed bottom-4 right-4"
        open={showToast}
        onOpenChange={setShowToast}
      >
        <Toast.Title className="text-sm font-medium text-slate-900 dark:text-white">
          {toastMessage}
        </Toast.Title>
      </Toast.Root>

      <Toast.Viewport className="fixed bottom-0 right-0 p-6 m-0 flex flex-col gap-2 w-96 list-none z-50" />
    </Toast.Provider>
  );
};
