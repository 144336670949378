import { ChevronDownIcon } from "@radix-ui/react-icons";
import * as Select from "@radix-ui/react-select";

import { WidgetModal } from "@/components/Modal/WidgetModal";
import {
  TransactionType,
  useBuySellStore,
} from "@/reducers/transactionReducer";
import {
  CoinOption,
  coinOptions,
  BUY_MAX_LIMIT_AMT,
  SELL_MAX_LIMIT_AMT,
} from "@/utils/currencies";
import { CurrencyInput } from "./CurrencyInput";

type SelectValueProps = {
  option: CoinOption;
  disabled?: boolean;
} & Select.SelectValueProps;

const SelectValue = ({ option, disabled }: SelectValueProps) => {
  return (
    <Select.Trigger
      aria-label={option.name}
      className={`inline-flex items-start justify-center text-base leading-none min-h-[35px] gap-[5px] outline-none focus:bg-neutral-200 dark:focus:bg-slate-600 border-rock border-b-2 p-1 ${
        disabled ? "pr-4" : ""
      }`}
      disabled={disabled}
    >
      <>
        <Select.Value aria-label={option.name}>
          <div className="flex">
            {option.image && (
              <div
                className="mr-1 md:mr-2 w-15"
                style={{ marginBottom: "-1px" }}
              >
                <img src={option.image} height={40} width={40} />
              </div>
            )}
            <div className="text-left flex flex-col justify-end mt-2">
              <div className="font-bold">{option?.name}</div>
              <div className="text-sm text-pebble dark:text-white">
                {option.shortDescription ?? option.description}
              </div>
            </div>
          </div>
        </Select.Value>
        {!disabled && (
          <Select.Icon>
            <ChevronDownIcon />
          </Select.Icon>
        )}
      </>
    </Select.Trigger>
  );
};

type CoinSelectProps = {
  options: CoinOption[];
  disabled?: boolean;
} & Select.SelectProps;

export const CoinSelect = ({
  options,
  disabled,
  ...restProps
}: CoinSelectProps) => {
  const selectedOption = options.find(
    (option) => option.value === restProps.value
  );
  return (
    <Select.Root {...restProps} disabled={disabled}>
      <SelectValue option={selectedOption as CoinOption} disabled={disabled} />
      <Select.Portal>
        <Select.Content
          position="popper"
          sideOffset={5}
          className="overflow-hidden w-[var(--radix-select-trigger-width)] bg-snow dark:bg-gravel border border-gravel dark:border-snow"
        >
          <Select.Viewport>
            {options.map((coinOption) => {
              return (
                <Select.Item
                  className="text-[13px] outline-none focus:bg-neutral-200 dark:focus:bg-slate-600 leading-none p-2 hover:bg-neutral-300 hover:dark:bg-pebble"
                  key={coinOption.value}
                  value={coinOption.value}
                >
                  <div className="flex items-center">
                    {coinOption.image && (
                      <div className="mr-2 mt-1">
                        <img src={coinOption.image} height={40} width={40} />
                      </div>
                    )}
                    <div>
                      <div className="font-bold">{coinOption.name}</div>
                    </div>
                  </div>
                </Select.Item>
              );
            })}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export const FiatSelect = ({
  options,
  disabled,
  ...restProps
}: CoinSelectProps) => {
  const selectedOption = options.find(
    (option) => option.value === restProps.value
  );
  return (
    <Select.Root {...restProps} disabled={disabled}>
      <SelectValue option={selectedOption as CoinOption} disabled={disabled} />
      <Select.Portal>
        <Select.Content
          position="popper"
          sideOffset={5}
          className="overflow-hidden w-[var(--radix-select-trigger-width)] bg-snow dark:bg-gravel border border-gravel dark:border-snow"
        >
          <Select.Viewport>
            {options.map((coinOption) => {
              return (
                <Select.Item
                  className="text-[13px] outline-none focus:bg-neutral-200 dark:focus:bg-slate-600 leading-none p-2 hover:bg-neutral-300 hover:dark:bg-pebble"
                  key={coinOption.value}
                  value={coinOption.value}
                >
                  <div className="flex">
                    {coinOption.image && (
                      <div className="mr-2 mt-1">
                        <img src={coinOption.image} height={40} width={40} />
                      </div>
                    )}
                    <div>
                      <div className="font-bold">{coinOption.name}</div>
                      <div className="text-pebble">
                        {coinOption.description}
                      </div>
                    </div>
                  </div>
                </Select.Item>
              );
            })}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export const StableCoinRow = () => {
  const transactionType = useBuySellStore.use.transactionType();
  const coinAmount = useBuySellStore.use.stableCoinAmount();
  const setStableCoinAmount = useBuySellStore.use.setStableCoinAmount();

  return (
    <div className="flex items-end">
      <div className="flex-grow">
        <CurrencyInput
          id="stablecoin"
          label={transactionType === TransactionType.BUY ? "Receive" : "Pay"}
          amount={coinAmount}
          setAmount={setStableCoinAmount}
          decimal={2}
          max={10010}
          min={0}
          autoFocus={transactionType === TransactionType.SELL}
        />
      </div>
      <div className="flex-shrink min-w-min flex items-end border-rock border-b-2">
        <WidgetModal />
      </div>
    </div>
  );
};

export const FiatCoinRow = () => {
  const transactionType = useBuySellStore.use.transactionType();
  const fiatAmount = useBuySellStore.use.fiatAmount();
  const setFiatAmount = useBuySellStore.use.setFiatAmount();
  const requestType = useBuySellStore.use.requestType();
  const setRequestType = useBuySellStore.use.setRequestType();
  return (
    <div className="flex items-end">
      <div className="flex-grow">
        <CurrencyInput
          id="fiat"
          label={transactionType === TransactionType.BUY ? "Pay" : "Receive"}
          amount={fiatAmount}
          setAmount={setFiatAmount}
          max={
            transactionType === TransactionType.BUY
              ? BUY_MAX_LIMIT_AMT
              : SELL_MAX_LIMIT_AMT
          }
          min={0}
          autoFocus={transactionType === TransactionType.BUY}
        />
      </div>
      <div
        className="flex-shrink min-w-min"
        style={{ marginBottom: "-2px", marginRight: "6px" }}
      >
        <FiatSelect
          name="requestType"
          disabled={true}
          options={coinOptions}
          value={requestType}
          onValueChange={setRequestType}
        />
      </div>
    </div>
  );
};
