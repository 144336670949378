import { useState } from "react";
import { isAxiosError } from "axios";

import { Button } from "@/components/Button/Button";
import { PoweredByLabel, PaytrieTopIcon } from "@/components/widget-components";
import { UploadFileComp } from "@/panels/signup/ManualCheck/UploadFile";
import { WidgetLayout } from "@/pages/Buy/WidgetLayout";
import { ImageData } from "@/requests/signup";
import { uploadFile } from "@/requests/upload";
import { DarkModeMenu } from "@/panels/signup/DarkmodeSignUpPanel";

const Upload = () => {
  const [error, setError] = useState("");
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [files, setFiles] = useState<ImageData[]>([]);
  const isInIframe = window.self !== window.top;

  const upload = async (email: string, fileData: ImageData) => {
    const newFilesList = [...files, fileData];

    const { response, error } = await uploadFile(email, newFilesList);
    if (response) {
      //console.log("RESPONSE:", response.data.status);
      setFileUploadSuccess(true);
    }
    if (error && isAxiosError(error)) {
      setError(
        "Unable to upload document. Please contact paytrie support at support@paytrie.com."
      );
    }
  };
  if (fileUploadSuccess) {
    return (
      <WidgetLayout showInteracLabel={false} showPoweredByLabel={false}>
        <DarkModeMenu />
        <div
          className="my-auto flex flex-col justify-center"
          style={{ paddingBottom: "400px" }}
        >
          <p className="text-center mt-40 mb-10">
            Your document was uploaded successfully.
          </p>
          <a
            href="/upload"
            className="mt-4 bg-black text-white dark:bg-semiWhite dark:text-black py-8 px-4 border-black w-full border-2 items-center text-center flex justify-center"
          >
            Upload Another Document
          </a>
        </div>
        <PoweredByLabel />
      </WidgetLayout>
    );
  }

  return (
    <WidgetLayout showInteracLabel={false} showPoweredByLabel={true}>
      <DarkModeMenu />
      <div className="flex items-center justify-center min-h-screen     overflow-hidden">
        <div
          className="w-full  dark:text-white py-0 "
          style={{
            position: "absolute",
            top: !isInIframe ? "9%" : "2%",
            width: "90%",
          }}
        >
          {/* <div className="flex items-center justify-center mb-8">
            <img src={PaytrieFullIcon} alt="PayTrie Logo" className="dark:invert" style={{ width: '200px', height: 'auto' }} />
          </div> */}
          <div
            className="text-lg font-bold uppercase justify-center items-center text-center"
            style={{ paddingTop: "5rem", paddingBottom: "30px" }}
          >
            SUPPLEMENTARY DOCUMENTATION UPLOAD
          </div>
          <div className="space-y-4"></div>
          <div className="mb-12">
            <UploadFileComp
              onConfirm={(fileData, email) => {
                upload(email, fileData);
              }}
            />
            {error && <div className="text-rose-600">{error}</div>}
            {/* <div style={{paddingTop: '40px'}}>
                  <PoweredByLabel /> 
                </div> */}
          </div>
        </div>
      </div>
    </WidgetLayout>
  );
};

export default Upload;
