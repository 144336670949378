import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button/Button";
import { LoginHeader, PoweredByLabel } from "@/components/widget-components";
import { Stepper } from "@/pages/Signup/Stepper/Stepper";
import { Pages, usePageStateStore } from "@/reducers";
import { DarkModeMenu } from "./DarkmodeSignUpPanel";

export const CompleteRegistrationStandard = () => {
  const navigate = useNavigate();
  const redirectURL = import.meta.env.VITE_COMPLETE_REDIRECT_URL;
  const redirectCustom =
    import.meta.env.VITE_CUSTOM_COMPLETE_REDIRECT === "TRUE" ? true : false;

  const returnToHome = () => {
    navigate("/EasyLogin");
  };
  const isIframe = window.self !== window.top;

  return (
    <div className="flex flex-col md:flex-row">
      <LoginHeader>
        <Stepper />
      </LoginHeader>
      <div className="relative p-5 lg:py-20 w-full">
        {window.self === window.top && (
          <div className="absolute top-4 right-0 md:top-10 md:right-0">
            <DarkModeMenu />
          </div>
        )}
        <div className="flex-grow mb-5">
          <div className="flex flex-col gap-5">
            <div className="text-lg font-bold uppercase mt-4">
              Thank you for registering with Paytrie
            </div>
            <br />
            <div className="mb-16">
              Please allow up to 24 hours for us to verify your identity. If we
              require more information, we will contact you by email.
            </div>
            <Button
              className="inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
              onClick={() => {
                redirectCustom
                  ? (window.location.href = redirectURL)
                  : returnToHome();
              }}
            >
              {import.meta.env.VITE_COMPLETE_REDIRECT_TEXT}
            </Button>
          </div>
        </div>

        <div
          className="absolute left-4 right-0 flex justify-center p-2"
          style={{
            top: isIframe
              ? window.innerWidth < 640
                ? "534px"
                : "534px"
              : window.innerWidth < 640
                ? "534px"
                : "660px",
          }}
        >
          <PoweredByLabel />
        </div>
      </div>
    </div>
  );
};
