import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import * as Select from "@radix-ui/react-select";

import { useSignupStore } from "@/reducers";

const employmentStatuses = [
  "Employed",
  "Self-Employed",
  "Retired",
  "Unemployed",
  "Student",
];

export const EmploymentStatusSelector = () => {
  const { employmentStatus } = useSignupStore();
  const setEmploymentStatus = useSignupStore.use.setEmploymentStatus();
  const setOccupation = useSignupStore.use.setOccupation();
  return (
    <Select.Root
      required
      value={employmentStatus}
      onValueChange={(value) => {
        setEmploymentStatus(value);
        if (value === "Employed" || value === "Self-Employed") {
          // clear occupation store
          setOccupation(undefined);
        } else {
          // set occupation store as employment status
          setOccupation(value);
        }
      }}
    >
      <Select.Trigger className="text-pebble dark:text-snow py-2 h-full w-full inline-flex items-end leading-none gap-[5px] min-w-[150px] justify-between data-[placeholder]:text-gray-400 dark:data-[placeholder]:text-gray-400 outline-none border-b-rock border-b-2">
        <Select.Value placeholder="Select option" />
        <Select.Icon className="text-gray-400">
          <ChevronDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className="bg-white dark:bg-pebble border border-pebble dark:border-white overflow-hidden">
          <Select.ScrollUpButton className="p-2.5">
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport>
            {employmentStatuses.map((employmentStatus) => {
              return (
                <Select.Item
                  key={employmentStatus}
                  value={employmentStatus}
                  className="hover:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                >
                  <Select.ItemText>{employmentStatus}</Select.ItemText>
                </Select.Item>
              );
            })}
          </Select.Viewport>
          <Select.ScrollDownButton className="p-2.5 text-gray-400">
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};
