import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import axios from "./axios";

const signupEndpoints = {
  SET_USER_PASSWORD: "changePassword",
  CHECK_EMAIL: "checkEmail",
  VERIFY_EMAIL: "verifyEmail",
  SEND_SMS: "sendSMS",
  VERIFY_SMS: "verifySms",
  UPDATE_USER: "updateUser",
  UPDATE_AND_VERIFY_USER: "updateAndVerifyUser",
  UPDATE_OLIU_SESSION: "updateOliuSession",
  VERIFY_USER: "verificationCheck",
  AML_CHECK: "amlCheck",
  VERIFICATION_LINK: "verificationLink",
  GENERATE_KYC_QUESTIONS: "generateKycQuestions",
  SUBMIT_KYC: "submitKycAnswers",
  UPLOAD_IMAGES: "createManualUser",
};

export async function setUserPassword(
  email: string,
  verification_code: string,
  password1: string,
  password2: string
) {
  try {
    const response = await axios.post(signupEndpoints.SET_USER_PASSWORD, {
      params: {
        email: email, //lowercase
        verification_code: verification_code, //from /createManualUser or /submitKycAnswers
        newpassword1: password1,
        newpassword2: password2, // validation done in form to ensure the password matches
      },
    });
    return { response };
  } catch (error) {
    return { error };
  }
}

export async function checkEmail(email: string, referral: string) {
  try {
    const response = await axios.post(signupEndpoints.CHECK_EMAIL, null, {
      params: {
        email,
        referral,
      },
    });
    return { response };
  } catch (error) {
    return { error };
  }
}

export async function verifyEmail(
  email: string,
  pin: string
): Promise<[err: AxiosError<any> | null, isAvailable: boolean]> {
  try {
    const response = await axios.post(
      signupEndpoints.VERIFY_EMAIL,
      {},
      { params: { email, email_code: pin } }
    );
    if (response.status === 200) {
      return [null, true];
    }

    const respData = JSON.parse(response.data);
    return [respData.message, false];
  } catch (error: any) {
    return [error, false];
  }
}

export async function sendSMS(
  email: string,
  phoneNumber: string
): Promise<[err: string | null, isAvailable: boolean]> {
  try {
    const response = await axios.post(
      signupEndpoints.SEND_SMS,
      {},
      {
        params: {
          email,
          phone: phoneNumber,
        },
      }
    );
    if (response.status === 200) {
      return [null, true];
    }
    const respData = JSON.parse(response.data);
    return [respData.message, false];
  } catch (error: any) {
    return [String(error.response.data.message), false];
  }
}

export async function verifySMS(
  email: string,
  phoneCode: string
): Promise<[err: string | null, isAvailable: boolean]> {
  try {
    const response = await axios.post(
      signupEndpoints.VERIFY_SMS,
      {},
      {
        params: { email, phone_code: phoneCode },
      }
    );
    if (response.status === 200) {
      return [null, true];
    }

    const respData = JSON.parse(response.data);
    return [respData.message, false];
  } catch (error: any) {
    return [String(error.response.data.message), false];
  }
}

export async function userVerification(email: string) {
  try {
    const response = await axios.post(
      signupEndpoints.VERIFY_USER,
      {},
      {
        params: { email },
      }
    );
    if (response.status === 200) {
      return response.data;
    }

    const respData = JSON.parse(response.data);
    return respData.message;
  } catch (error) {
    return { error };
  }
}

export async function amlCheck(
  email: string,
  firstName: string,
  lastName: string
) {
  try {
    const response = await axios.post(
      signupEndpoints.AML_CHECK,
      {},
      {
        params: {
          email,
          firstName,
          lastName,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    }

    const respData = JSON.parse(response.data);
    return respData.message;
  } catch (error) {
    return { error };
  }
}

export async function updateUser(
  email: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  dob: string,
  address1: string,
  address2: string,
  city: string,
  province: string,
  postalCode: string,
  occupation: string,
  purpose: string,
  pep: boolean,
  tpd: boolean,
  partner: string
) {
  const body = {
    first_name: firstName,
    last_name: lastName,
    phone: phoneNumber,
    dob, // '2000-12-31',
    address1,
    address2,
    city,
    province, // 'AB',
    postal: postalCode.replace(/\s/g, "").toLowerCase(), // 'A1A1A1',
    occupation, // 'Accountant',
    purpose,
    pep,
    tpd,
    ...(import.meta.env.VITE_USE_PARTNER_CODE && { partner_id: partner }),
  };

  try {
    const response = await axios.post<{ success: boolean }>(
      signupEndpoints.UPDATE_USER,
      body,
      {
        params: {
          email,
        },
      }
    );
    return { response };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response && error.response.data) {
        const errObj = error.response.data;
        return { error: errObj };
      }
    }
    return { error: new Error("An unknown error occurred") };
  }
}

export async function updateAndVerifyUser(
  email: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  dob: string,
  address1: string,
  address2: string,
  city: string,
  province: string,
  postalCode: string,
  occupation: string,
  purpose: string,
  pep: boolean,
  tpd: boolean,
  partner: string
) {
  const body = {
    first_name: firstName,
    last_name: lastName,
    //phone: phoneNumber,
    dob, // '2000-12-31',
    address1,
    address2,
    city,
    province, // 'AB',
    postal: postalCode.replace(/\s/g, "").toLowerCase(), // 'A1A1A1',
    occupation, // 'Accountant',
    purpose,
    pep,
    tpd,
    ...(import.meta.env.VITE_USE_PARTNER_CODE && { partner_id: partner }),
  };

  try {
    const response = await axios.post<{ status: boolean }>(
      signupEndpoints.UPDATE_AND_VERIFY_USER,
      body,
      {
        params: {
          email,
        },
      }
    );
    return { response };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response && error.response.data) {
        const errObj = error.response.data;
        return { error: errObj };
      }
    }
    return { error: new Error("An unknown error occurred") };
  }
}

// Validates that the input string is a valid date formatted as "yyyy-mm-dd"
export function isValidDate(dateString: string): boolean {
  // Parse the date parts to integers
  const parts = dateString.split("-");
  const day = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[0], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

// of form V6J1K8
export function testCanadianPostalCode(postalCode: string): boolean {
  const postalCodeRegex =
    //  /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY]\d$/i;
    /([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i;
  return postalCodeRegex.test(postalCode);
}

export function testIsValidName(input: string): boolean {
  const isAlphaRegex = /^[a-zA-Z]+(?:[\s.'-][a-zA-Z]+)*$/;
  return isAlphaRegex.test(input);
}

export function getUserAge(year: number, month: number, day: number): number {
  const today = new Date();
  const birthDate = new Date(year, month, day);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() + 1 - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export interface FormattedKycQuestion {
  question: string;
  answers: string[];
}

export type GenerateKycQuestionResponse = {
  multiChoice: {
    txKey: string;
    questions: string[];
    answer: string[];
  };
};

function formatKycQuestions(
  resp: AxiosResponse<GenerateKycQuestionResponse>,
  answersPerQuestion = 5
): FormattedKycQuestion[] {
  const formattedData = [];
  const questionData = resp.data.multiChoice;

  return questionData.questions.map((question, index) => {
    return {
      question,
      answers: questionData.answer.slice(
        index * answersPerQuestion,
        (index + 1) * answersPerQuestion
      ),
    };
  });

  return formattedData;
}

export async function generateKycQuestions(
  email: string
): Promise<
  [err: string | null, questions: FormattedKycQuestion[], txKey: string]
> {
  // return [null, formatKycQuestions(kycExampleResponse)];
  try {
    const response = await axios.post<GenerateKycQuestionResponse>(
      signupEndpoints.GENERATE_KYC_QUESTIONS,
      {
        params: {
          email,
        },
      }
    );
    if (response) {
      const questions = formatKycQuestions(response);
      return [null, questions, response.data.multiChoice.txKey];
    }

    return ["An unexpected error occurred", [], ""];
  } catch (error) {
    if (isAxiosError(error)) {
      return [String(error.message), [], ""];
    }
    return ["An unexpected error occurred", [], ""];
  }
}

export async function submitKycAnswers(
  email: string,
  txKey: string,
  answers: string[]
) {
  try {
    const response = await axios.post(signupEndpoints.SUBMIT_KYC, {
      email,
      txKey,
      answers,
    });
    if (response.data && response.data.token) {
      sessionStorage.setItem("authToken", response.data.token);
      return response.data.token;
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

export type ImageData = {
  name: string;
  type: string;
  url: string;
};

export async function uploadImages(
  email: string,
  images: ImageData[],
  idType: string,
  idNumber: string,
  accountNumber: string,
  accountType: string,
  companyName: string,
  amlCheck: boolean
) {
  try {
    const response = await axios.post<{
      token: string;
      verification_code: string;
      success: boolean;
    }>(
      signupEndpoints.UPLOAD_IMAGES,
      {
        idType,
        base64image: images,
        idNumber,
        accountNumber,
        accountType,
        companyName,
        ...(amlCheck && { high_risk: true }),
      },
      {
        params: {
          email,
        },
      }
    );
    return {
      response,
    };
  } catch (e) {
    return { error: e };
  }
}

export async function generateVerificationLink(email: string, type: string) {
  try {
    const response = await axios.post<{
      url: string;
      sessionId: string;
    }>(
      signupEndpoints.VERIFICATION_LINK,
      {},
      {
        params: {
          email,
          type,
        },
      }
    );

    return { url: response.data.url, sessionId: response.data.sessionId };
  } catch (error: any) {
    return { errorMessage: error.response.data.message };
  }
}

export async function updateUserCurrentFlow(
  email: string,
  currentFlow: string
) {
  const body = {
    current_flow: currentFlow,
  };
  try {
    const response = await axios.post<{ success: boolean }>(
      signupEndpoints.UPDATE_USER,
      body,
      {
        params: {
          email,
        },
      }
    );
    return { response };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response && error.response.data) {
        const errObj = error.response.data;
        return { error: errObj };
      }
    }
    return { error: new Error("An unknown error occurred") };
  }
}

export async function setOliuDesktopFlow(
  session_id: string,
  isDesktopFlow: boolean
) {
  const body = {
    session_id,
    is_oliu_desktop: isDesktopFlow,
  };
  try {
    const response = await axios.post<{ success: boolean }>(
      signupEndpoints.UPDATE_OLIU_SESSION,
      body
    );
    return { response };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response && error.response.data) {
        const errObj = error.response.data;
        return { error: errObj };
      }
    }
    return { error: new Error("An unknown error occurred") };
  }
}
