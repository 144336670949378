import { ReactNode } from "react";

import { PoweredByLabel } from "../../components/widget-components";
import PaytrieWelcomeLeftPanel from "./PaytrieWelcomeLeftPanel";
import { DarkModeMenu } from "../signup/DarkmodeSignUpPanel";

const isIframe = window.self !== window.top;

export const PanelLayout = ({ children }: { children: ReactNode }) => {
  const Footer = () => {
    return (
      <div
        className="absolute left-0 right-0 flex justify-center p-2"
        style={{
          top: isIframe
            ? window.innerWidth < 640
              ? "534px"
              : "534px"
            : window.innerWidth < 640
              ? "534px"
              : "660px",
        }}
      >
        <PoweredByLabel />
      </div>
    );
  };

  return (
    <div
      className="flex flex-col md:flex-row gap-4 relative mx-auto"
      style={{
        maxHeight: "100%",

        width: "100%",
      }}
    >
      {window.self == window.top && (
        <div className="absolute z-50 top-32 right-2 md:top-10 md:right-0">
          <DarkModeMenu />
        </div>
      )}
      <PaytrieWelcomeLeftPanel />
      <div
        className="flex flex-col relative p-10 md:py-24 md:px-4 md:flex-grow"
        style={{}}
      >
        <div className="flex flex-grow">{children}</div>
        <Footer />
      </div>
    </div>
  );
};
