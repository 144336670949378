import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "@/contexts/auth-context";

export const ProtectedRoute = () => {
  const { isAuth, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Account loading...</div>;
  }

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/EasyLogin" replace state={{ from: location }} />
  );
};
