import clsx from "clsx";

import CAD from "@/assets/currency/cad.png";
import {
  TransactionType,
  useBuySellStore,
} from "@/reducers/transactionReducer";
import { StableCoins, stableCoinLogos } from "@/utils/currencies";

type SummaryRowProps = {
  startLabel?: string;
  endLabel?: string;
  useReferral?: boolean;
};

export const SummaryRow = ({
  startLabel = "You pay",
  endLabel = "You receive",
  useReferral = false,
}: SummaryRowProps) => {
  const transactionType = useBuySellStore.use.transactionType();
  const fiatAmount = useBuySellStore.use.fiatAmount();
  return (
    <div className="grid grid-cols-4 md:grid-cols-[minmax(400px,1fr)_1fr_1fr_2fr] gap-y-3">
      {transactionType === TransactionType.BUY && (
        <>
          <FiatRow isTop label={startLabel} />
          <CoinRow label={endLabel} useReferral={useReferral} />
        </>
      )}
      {transactionType === TransactionType.SELL && (
        <>
          <CoinRow
            isTop
            label={startLabel}
            useReferral={fiatAmount >= 9985 ? useReferral : false}
          />
          <FiatRow label={endLabel} useReferral={useReferral} />
        </>
      )}
    </div>
  );
};

type CoinRowProps = {
  label: string;
  isTop?: boolean;
  useReferral?: boolean;
};

const topRowCellClass = "pb-3";

export const FiatRow = ({
  label,
  isTop,
  useReferral = false,
}: CoinRowProps) => {
  const fiatAmount = useBuySellStore.use.fiatAmount();
  const adjustedFiatAmount = useBuySellStore.use.referralAdjustedFiatAmount();
  const difference = adjustedFiatAmount - fiatAmount;
  return (
    <>
      <div
        className={clsx({
          [topRowCellClass]: isTop,
        })}
      >
        {label}
      </div>
      <div
        className={clsx("flex justify-center items-start", {
          [topRowCellClass]: isTop,
        })}
      >
        <img src={CAD} height={25} width={25} />
      </div>
      {useReferral && fiatAmount < 9985 && (
        <div className={clsx({ [topRowCellClass]: isTop }, "mr-4")}>
          {/* <s style={{color: 'red'}}>{fiatAmount.toFixed(2)}</s>  {adjustedFiatAmount.toFixed(2)} */}
          <s style={{ color: "grey" }}>{fiatAmount.toFixed(2)}</s>
        </div>
      )}
      {(!useReferral || (useReferral && fiatAmount >= 9985)) && (
        <div className={clsx({ [topRowCellClass]: isTop }, "mr-4")}>
          {fiatAmount.toFixed(2)}
        </div>
      )}

      {useReferral && fiatAmount < 9985 && (
        <div className={clsx({ [topRowCellClass]: isTop }, "mr-4")}>
          <s style={{ color: "grey" }}>CAD</s>
        </div>
      )}

      {(!useReferral || (useReferral && fiatAmount >= 9985)) && (
        <div className={clsx({ [topRowCellClass]: isTop }, "mr-4")}>CAD</div>
      )}

      {useReferral && fiatAmount < 9985 && <div></div>}

      {useReferral && fiatAmount < 9985 && <div></div>}

      {useReferral && fiatAmount < 9985 && <div></div>}

      {useReferral && fiatAmount < 9985 && (
        <div>
          {difference > 0 ? "+" : ""} {difference.toFixed(2)}
        </div>
      )}

      {useReferral && fiatAmount < 9985 && <div></div>}

      {useReferral && fiatAmount < 9985 && <div></div>}

      {useReferral && fiatAmount < 9985 && (
        <div>{adjustedFiatAmount.toFixed(2)}</div>
      )}

      {useReferral && fiatAmount < 9985 && (
        <div className={clsx({ [topRowCellClass]: isTop })}>CAD</div>
      )}
    </>
  );
};

export const CoinRow = ({
  label,
  isTop,
  useReferral = false,
}: CoinRowProps) => {
  const stableCoin = useBuySellStore.use.stableCoin();
  const stableCoinAmount = useBuySellStore.use.stableCoinAmount();
  const adjustedCoinAmount = useBuySellStore.use.referralAdjustedCoinAmount();
  const network = useBuySellStore.use.network();
  const difference = adjustedCoinAmount - stableCoinAmount;
  return (
    <>
      <div className={clsx({ [topRowCellClass]: isTop })}>{label}</div>
      <div
        className={clsx("flex justify-center items-start", {
          [topRowCellClass]: isTop,
        })}
      >
        <img
          src={stableCoinLogos[`${stableCoin}${network}`]}
          height={25}
          width={25}
        />
      </div>
      {useReferral && (
        <div className={clsx({ [topRowCellClass]: isTop })}>
          {/* <s style={{color: 'red'}}>{stableCoinAmount.toFixed(2)}</s>  {adjustedCoinAmount.toFixed(2)} */}
          <s style={{ color: "grey" }}>{stableCoinAmount.toFixed(2)}</s>
        </div>
      )}
      {!useReferral && (
        <div className={clsx({ [topRowCellClass]: isTop })}>
          {stableCoinAmount.toFixed(2)}
        </div>
      )}

      {useReferral && (
        <div className={clsx({ [topRowCellClass]: isTop })}>
          <s style={{ color: "grey" }}>
            {StableCoins[stableCoin]}-{network}
          </s>
        </div>
      )}

      {!useReferral && (
        <div className={clsx({ [topRowCellClass]: isTop })}>
          {StableCoins[stableCoin]}-{network}
        </div>
      )}

      {useReferral && <div></div>}

      {useReferral && <div></div>}

      {useReferral && <div></div>}

      {useReferral && (
        <div>
          {difference > 0 ? "+" : ""} {difference.toFixed(2)}
        </div>
      )}

      {useReferral && <div></div>}

      {useReferral && <div></div>}

      {useReferral && <div>{adjustedCoinAmount.toFixed(2)}</div>}

      {useReferral && (
        <div className={clsx({ [topRowCellClass]: isTop })}>
          {StableCoins[stableCoin]}-{network}
        </div>
      )}
    </>
  );
};
