import { FormEvent, useState } from "react";

import { Button } from "@/components/Button/Button";
import { ImageData } from "@/requests/signup";
import { TextInput } from "@/components/widget-components";
import { FileUploader } from "./FileUploader";

type UploadFileProps = {
  onConfirm: (
    fileData: ImageData,
    //accountType: string,
    //idNumber: string
    email: string
  ) => void;
};

export const UploadFileComp = ({ onConfirm }: UploadFileProps) => {
  const [idType, setIdType] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [error, setError] = useState("");
  const [fileData, setFileData] = useState<ImageData | null>(null);
  const [email, setEmail] = useState<string>("");

  const handleSuccess = (fileData: ImageData) => {
    setFileData(fileData);
    setError("");
  };

  const handleConfirm = (e: FormEvent) => {
    e.preventDefault();
    if (fileData) {
      onConfirm(fileData, email);
    }
  };

  return (
    <form className="flex flex-col gap-5" onSubmit={handleConfirm}>
      {/* <div>
      Upload your supporting document here. Make sure the document includes your name, address, and account number.
      </div> */}
      <div>
        <TextInput
          autoFocus
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      {error && <div className="text-rose-600">{error}</div>}
      {fileData && (
        <div className="flex justify-center invisible md:visible">
          <img src={fileData.url} width={100} />
        </div>
      )}
      {/* {!fileData && (
        <div className="hidden md:flex justify-center">
          <div className="h-[150px] w-[100px] border-dashed border border-gray-300 dark:border-neutral-200 flex justify-center items-center px-2 py-2">
            Image
          </div>
        </div>
      )} */}
      <div className="flex justify-center mb-8" style={{ width: "100%" }}>
        <div style={{ width: "50%" }}>
          <FileUploader
            onUploadComplete={handleSuccess}
            onError={setError}
            customButtonText="Choose File"
            showUploadIcon={false}
            showUploadSVG={true}
          />
        </div>
      </div>
      <a
        className="bg-black text-white dark:bg-semiWhite dark:text-black py-8 px-4 border-black w-full border-2 items-center text-center flex justify-center"
        onClick={(e) => {
          e.preventDefault();
          if (!fileData || !!error || !email) {
            return;
          }
          handleConfirm(e);
        }}
        role="button"
        aria-disabled={!fileData || !!error || !email}
        href="#"
      >
        SUBMIT
      </a>
    </form>
  );
};
