import { FormEvent, useState } from "react";
import * as Toast from "@radix-ui/react-toast";
import { useNavigate } from "react-router-dom";
import ReactSpinnerTimer from "react-spinner-timer";

import { Button } from "@/components/Button/Button";
import {
  BlockText,
  ErrorBlockText,
  TextInput,
  LeftAlignSmallText,
} from "@/components/widget-components";
import { LoadingIcon } from "@/components/LoadingIcon";
import { useSignupStore } from "@/reducers";
import {
  checkEmail,
  verifyEmail,
  updateUserCurrentFlow,
} from "@/requests/signup";
import { submitOnEnter } from "@/utils/keyboard";
import { FormStates } from "./FormStateMachine";

export default function SignupContentVerifyEmail() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [codeSentSuccess, setCodeSentSuccess] = useState(true);
  const [open, setOpen] = useState(false);
  const setFormState = useSignupStore.use.nextState();
  const referralCode = useSignupStore.use.referralCode();
  const email = useSignupStore.use.email();
  const [pin, setPin] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  //const codeSentMsg = (codeSentSuccess) ? 'Code sent' : 'Unable to send code';

  const resendCode = async () => {
    setIsLoading(true);
    checkEmail(email, referralCode || "")
      .then(() => {
        setCodeSentSuccess(true);
        setClicked(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setCodeSentSuccess(false);
        setClicked(true);
        setIsLoading(false);
      });
  };

  const updateSpinTimer = (lap: any) => {
    if (lap.isFinished) {
      setClicked(false);
    } else {
      if (lap.actualLap === lap.totalLaps) {
        setClicked(false);
      }
    }
  };

  async function verifyEmailPin(e: FormEvent): Promise<void> {
    e.preventDefault();
    if (pin.length < 4) {
      setErrorMessage("Invalid pin");
    }
    setIsLoading(true);
    const [err] = await verifyEmail(email, pin);
    if (err === null) {
      setErrorMessage("");
      setFormState();
      // set flow
      await updateUserCurrentFlow(email, FormStates.VERIFY_EMAIL);
      navigate("/SignupPhone");
    } else if (err.response?.data?.message === "Mismatched verification code") {
      setErrorMessage(
        "The verification code you entered does not match. Please check the code and try again."
      );
    } else if (
      err.response?.data.message ===
      "Failed too many attempts. New SMS code sent"
    ) {
      setErrorMessage("Failed too many attempts. New SMS code sent");
    } else {
      setErrorMessage(
        "Unfortunately we were unable to process your request at this time. Please try again later or contact us at support@paytrie.com"
      );
    }
    setIsLoading(false);
  }
  let errorMitigations = null;
  if (errorMessage !== "" && pin.length) {
    errorMitigations = (
      <BlockText onClick={setFormState}>Update email address </BlockText>
    );
  }

  if (isLoading) {
    return (
      <div className="my-auto flex justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <form onSubmit={verifyEmailPin}>
      <div className="flex flex-col gap-6">
        <div className="text-lg font-bold uppercase">Verify Email</div>
        <div className="base-text">
          We've sent an email to {email}. Enter the code below.
        </div>
        <TextInput
          autoFocus
          placeholder="Verification code"
          type="tel"
          inputMode="numeric"
          min={0}
          onChange={(e) =>
            setPin(e.target.value.replace(/[^0-9\+]/g, "").substring(0, 4))
          }
          onKeyDown={submitOnEnter(verifyEmailPin)}
          value={pin}
        />
        <div className="mb-[-40px]">
          <LeftAlignSmallText className="flex">
            <Toast.Provider swipeDirection="right">
              <button
                className="codeButton"
                type="button"
                style={{ float: "left", textDecoration: "underline" }}
                disabled={clicked}
                onClick={() => {
                  setOpen(true);
                  resendCode();
                }}
              >
                Resend code
              </button>
              <Toast.Root
                className="ToastRoot"
                open={open}
                onOpenChange={setOpen}
              >
                {codeSentSuccess && (
                  <Toast.Title className="ToastTitle">Code sent</Toast.Title>
                )}
                {!codeSentSuccess && (
                  <Toast.Title className="ToastTitle">
                    Unable to send code
                  </Toast.Title>
                )}
              </Toast.Root>
              <Toast.Viewport className="ToastViewport" />
            </Toast.Provider>
            {clicked && (
              <div style={{ scale: "0.5" }}>
                <ReactSpinnerTimer
                  timeInSeconds={60}
                  totalLaps={1}
                  onLapInteraction={updateSpinTimer}
                  isPaused={false}
                />
              </div>
            )}
          </LeftAlignSmallText>
        </div>
        <ErrorBlockText>{errorMessage}</ErrorBlockText>
        <Button
          className="inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
          type="submit"
          disabled={pin.length < 4}
        >
          Continue
        </Button>
      </div>
    </form>
  );
}
