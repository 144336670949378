import clsx from "clsx";
import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";

import { TextInput } from "@/components/widget-components";
import { useSignupStore } from "@/reducers";
import occupationList from "@/utils/occupations.json";

const occupations = occupationList.OCCUPATIONS;

const CustomList = React.forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ style, ...props }, ref) => {
  return (
    <div
      style={{
        ...style,
      }}
      className="border border-gray-300 bg-white dark:bg-pebble"
      ref={ref}
      {...props}
    />
  );
});
CustomList.displayName = "CustomList";

interface OccupationSelectorProps {
  value: string;
  onChange: (value: string) => void;
}

export const OccupationSelector: React.FC<OccupationSelectorProps> = ({
  value,
  onChange,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const [filteredOccupations, setFilteredOccupations] = useState<string[]>([]);
  const setOccupation = useSignupStore.use.setOccupation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  const handleOccupationsFiltering = (value: string) => {
    const trimmedValue = value.toLowerCase().trim();
    if (trimmedValue) {
      const filtered = occupations.filter((item) =>
        item.toLowerCase().includes(trimmedValue)
      );
      setFilteredOccupations([...filtered, "Other"]);
    } else {
      setFilteredOccupations([]);
    }
  };

  useEffect(() => {
    // Attach click event listener to document to handle clicks outside of the dropdown
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Remove click event listener when component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="relative" ref={dropdownRef}>
        <TextInput
          placeholder="Start typing to search..."
          value={value}
          onChange={(e) => {
            const inputValue = e.target.value;
            onChange(inputValue);
            handleOccupationsFiltering(inputValue);
            setShowDropdown(!!inputValue.trim());
          }}
          onFocus={() => {
            if (value.trim()) setShowDropdown(true);
          }}
        />
        {showDropdown && (
          <Virtuoso
            components={{ List: CustomList }}
            style={{
              height: 342,
              width: "100%",
              position: "absolute",
            }}
            totalCount={filteredOccupations.length}
            itemContent={(index) => (
              <div
                key={filteredOccupations[index]}
                onClick={() => {
                  onChange(filteredOccupations[index]);
                  setShowDropdown(false);
                }}
                className={clsx(
                  `hover:bg-rock min-w-[150px] justify-between p-2 cursor-pointer`
                )}
              >
                {filteredOccupations[index]}
              </div>
            )}
          />
        )}
      </div>
    </>
  );
};
