import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import * as Select from "@radix-ui/react-select";

import { useSignupStore } from "@/reducers";

const provinces = [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NT",
  "NS",
  "NU",
  "ON",
  "PE",
  "QC",
  "SK",
  "YT",
];

type ProvinceSelectorProps = {
  province: string | undefined;
  onSelectProvince: (province: string) => void;
}

export const ProvinceSelector = ({province, onSelectProvince}: ProvinceSelectorProps) => {

  return (
    <Select.Root
      value={province}
      onValueChange={(value) => {
        onSelectProvince(value as string);
      }}
    >
      <Select.Trigger
        type="button"
        className="text-pebble dark:text-snow py-2 h-full w-full inline-flex items-end gap-[5px] min-w-[150px] justify-between data-[placeholder]:text-gray-400 dark:data-[placeholder]:text-gray-400 outline-none border-b-rock border-b-2"
      >
        <div className="relative top-[4px] flex w-full justify-between">
          <Select.Value placeholder="Select province" />
        </div>
        <Select.Icon className="">
          <ChevronDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className="bg-white dark:bg-pebble overflow-hidden border border-pebble dark:border-white">
          <Select.ScrollUpButton className="p-2.5">
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport>
            {provinces.map((province) => {
              return (
                <Select.Item
                  key={province}
                  value={province}
                  className="hover:bg-rock min-w-[150px] justify-between p-2 cursor-pointer"
                >
                  <Select.ItemText>{province}</Select.ItemText>
                </Select.Item>
              );
            })}
          </Select.Viewport>
          <Select.ScrollDownButton className="p-2.5">
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

{
  /* <Select.Root
          name="provinces"
          value={address.province}
          onValueChange={(val) => setProvince(val)}
        >
          <Select.Trigger
            className="p-2.5 inline-flex items-center gap-1 min-w-[150px] justify-between"
            aria-label="Token"
          >
            <Select.Value placeholder="Province" />
            <Select.Icon>
              <ChevronDownIcon />
            </Select.Icon>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content
              position="popper"
              className="bg-white dark:bg-pebble overflow-hidden"
            >
              <Select.Viewport>
                <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
                  <ChevronUpIcon />
                </Select.ScrollUpButton>
                {provinces.map((province) => {
                  return (
                    <Select.Item
                      className="hover:bg-rock min-w-[150px] justify-between p-2"
                      value={province}
                      key={province}
                    >
                      {province}
                    </Select.Item>
                  );
                })}
                <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white cursor-default">
                  <ChevronDownIcon />
                </Select.ScrollDownButton>
              </Select.Viewport>
            </Select.Content>
          </Select.Portal>
        </Select.Root> */
}
