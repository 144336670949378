import { createSelectorFunctions } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

type ProfileState = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
  address: {
    streetAddress1: string;
    streetAddress2: string;
    province: undefined | string;
    postalCode: string;
    city: string;
  };
  interacETransfer: {
    question: string;
    answer: string;
  };
  referral: {
    code: string;
    link: string;
    credits: undefined | number;
  };
};

const initialState: ProfileState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  birthDay: "",
  birthMonth: "",
  birthYear: "",
  address: {
    streetAddress1: "",
    streetAddress2: "",
    province: undefined,
    postalCode: "",
    city: "",
  },
  interacETransfer: {
    question: "",
    answer: "",
  },
  referral: {
    code: "",
    link: "",
    credits: undefined,
  },
};

type Actions = {
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setEmail: (email: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setAddress1: (address: string) => void;
  setAddress2: (address: string) => void;
  setCity: (city: string) => void;
  setProvince: (province: string) => void;
  setPostalCode: (postalCode: string) => void;
  setBirthDay: (day: string) => void;
  setBirthMonth: (month: string) => void;
  setBirthYear: (year: string) => void;
  setInteracETransferQuestion: (question: string) => void;
  setInteracETransferAnswer: (answer: string) => void;
  setReferralCode: (code: string) => void;
  setReferralLink: (link: string) => void;
  setReferralCredits: (credits: number) => void;
};

const createProfileStore = create<ProfileState & Actions>()(
  immer<ProfileState & Actions>((set) => ({
    ...initialState,
    setEmail: (email: string) =>
      set((state) => {
        state.email = email;
      }),
    setPhoneNumber: (phoneNumber: string) =>
      set((state) => {
        state.phoneNumber = phoneNumber;
      }),
    setFirstName: (firstName: string) =>
      set((state) => {
        state.firstName = firstName;
      }),
    setLastName: (lastName: string) =>
      set((state) => {
        state.lastName = lastName;
      }),
    setAddress: (address: ProfileState["address"]) =>
      set((state) => {
        state.address = address;
      }),
    setAddress1: (address: string) =>
      set((state) => {
        state.address.streetAddress1 = address;
      }),
    setAddress2: (address: string) =>
      set((state) => {
        state.address.streetAddress2 = address;
      }),
    setPostalCode: (postalCode: string) =>
      set((state) => {
        state.address.postalCode = postalCode;
      }),
    setProvince: (province: string) =>
      set((state) => {
        state.address.province = province;
      }),
    setCity: (city: string) =>
      set((state) => {
        state.address.city = city;
      }),
    setBirthDay: (birthDay: string) =>
      set((state) => {
        state.birthDay = birthDay;
      }),
    setBirthMonth: (birthMonth: string) =>
      set((state) => {
        state.birthMonth = birthMonth;
      }),
    setBirthYear: (birthYear: string) =>
      set((state) => {
        state.birthYear = birthYear;
      }),
    setInteracETransferQuestion: (question: string) =>
      set((state) => {
        state.interacETransfer.question = question;
      }),
    setInteracETransferAnswer: (answer: string) =>
      set((state) => {
        state.interacETransfer.answer = answer;
      }),
    setReferralCode: (code: string) =>
      set((state) => {
        state.referral.code = code;
      }),
    setReferralLink: (link: string) =>
      set((state) => {
        state.referral.link = link;
      }),
    setReferralCredits: (credits: number) =>
      set((state) => {
        state.referral.credits = credits;
      }),
  }))
);

export const useProfileStore = createSelectorFunctions(createProfileStore);
