import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button/Button";
import { LoadingIcon } from "@/components/LoadingIcon";
import {
  ErrorBlockText,
  TextInput,
  LeftAlignSmallText,
} from "@/components/widget-components";
import { useSignupStore } from "@/reducers";
import { updateUserCurrentFlow } from "@/requests/signup";
import { submitOnEnter } from "@/utils/keyboard";
import { FormStates } from "./FormStateMachine";

export default function SignupDisclosure() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const email = useSignupStore.use.email();

  if (isLoading) {
    return (
      <div className="my-auto flex justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="text-lg font-semibold uppercase">Disclosure</div>
      <div className="text-base">Before you trade:</div>

      <LeftAlignSmallText className="mt-6" style={{ paddingBottom: "1em" }}>
        <ol className="list-decimal space-y-3 ml-3">
          <li>
            Cryptocurrencies are highly speculative and you may lose some or all
            of your money.
          </li>
          <li>
            Do your research to understand the risks. Paytrie offers{" "}
            <a
              href={import.meta.env.VITE_PAYTRIE_EDU_LINK_URL}
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              educational resources
            </a>{" "}
            to help you learn more about cryptocurrencies.
          </li>
          <li>
            Protect yourself from frauds and scams, such as employment,
            investment, pig butchering, and romance scams.
          </li>
          <li>
            Cryptocurrency transactions cannot be reversed once completed.
          </li>
        </ol>
      </LeftAlignSmallText>
      <div className="mb-8">
        <LeftAlignSmallText style={{ paddingBottom: "0em" }}>
          <p className="font-semibold">
            By tapping "Continue", you acknowledge having received, read, and
            understood these risks.{" "}
          </p>
        </LeftAlignSmallText>
      </div>

      <Button
        className="inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
        onClick={() => {
          // set flow
          updateUserCurrentFlow(email, FormStates.DISCLOSURE);
          navigate("/SignupUserInfo");
        }}
      >
        Continue
      </Button>
    </div>
  );
}
